export interface ITranslationsDatepicker {
  [key: string]: string;
  L: string;
  M: string;
  X: string;
  J: string;
  V: string;
  S: string;
  D: string;
  mes_1: string;
  mes_2: string;
  mes_3: string;
  mes_4: string;
  mes_5: string;
  mes_6: string;
  mes_7: string;
  mes_8: string;
  mes_9: string;
  mes_10: string;
  mes_11: string;
  mes_12: string;
}
export interface ILanguageDatepicker {
  language: string;
  translations: ITranslationsDatepicker;
}
export const translations: ILanguageDatepicker[] = [
  {
    language: "es",
    translations: {
      L: "L",
      M: "M",
      X: "X",
      J: "J",
      V: "V",
      S: "S",
      D: "D",
      mes_1: "Enero",
      mes_2: "Febrero",
      mes_3: "Marzo",
      mes_4: "Abril",
      mes_5: "Mayo",
      mes_6: "Junio",
      mes_7: "Julio",
      mes_8: "Agosto",
      mes_9: "Septiembre",
      mes_10: "Octubre",
      mes_11: "Noviembre",
      mes_12: "Diciembre",
    },
  },
];
