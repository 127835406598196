import { IOfertaParams, IOfertasService } from "@/services/saldosonline/ofertas/ofertas.types";
import { IOferta } from "@/entities/centros-efectivo-backend/oferta.types";
import { APPLICATION_JSON } from "@/constans";
import { BACKEND_BASE_URL } from "@/services";
import { handleResponse } from "@/services/handlers";
import { IOfertaDemanda, IResponseError } from "@/entities";
import Vue from "vue";
import { i18n } from "@/i18n";

export class OfertasService implements IOfertasService {
  async saveUpdateOferta(params: IOfertaParams): Promise<IOferta | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IOferta, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/oferta`, params, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        text: `${error.response?.data}`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async cancelOferta(idOferta: number): Promise<IOferta | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IOferta, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/oferta/cancelar?id=` + idOferta, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        text: `${error.response?.data}`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }

  async ofertasCompatibles(idDemanda: number): Promise<IOfertaDemanda[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IOfertaDemanda[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/oferta/compatibles/` + idDemanda, { headers })
    );
    if (error) {
      Vue.swal({
        title: i18n.t("error").toString(),
        icon: "error",
        text: `${error.response?.data}`,
      });
      return undefined;
    } else {
      return response!.data;
    }
  }
}
