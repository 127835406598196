import { IRol, IUsuario } from "@/entities";
import { IPaged, IResponse } from "@/services";

export interface IUsuarioService {
  _me(): Promise<IUsuario | undefined>;
  _fetch(params: IUsuarioFilter): Promise<IUsuarioResponse | undefined>;
  _update(soUsuarioUpdateDTO: IUsuarioParams): Promise<IUsuario | undefined>;
  updateAcceptDisclaimer(
    soUsuarioUpdateDTO: IUsuarioUpdateDisclaimer
  ): Promise<IUsuario | undefined>;
}

export interface IUsuarioResponse extends IResponse<IUsuario> {}

export interface IUsuarioFilter extends IPaged {
  [key: string]: any;
  id?: number;
  codUsuario?: string;
  desNombre?: string;
  desApellidos?: string;
  codEmail?: string;
  ntfActiva?: boolean;
  active?: boolean;
  nivel?: number;
  configurado?: boolean;
  nombreApellido?: string;
  celular?: string;
  fechaBaja?: string;
  fechaCreacion?: string;
  fechaModificacion?: string;
  ntfActivaDesc?: string;
  activeDesc?: string;
}

export interface IUsuarioServiceMock {
  _me: jest.Mock<Promise<IUsuario | undefined>>;
  _fetch: jest.Mock<Promise<IUsuarioResponse | undefined>>;
  _update: jest.Mock<Promise<IUsuario | undefined>>;
  updateAcceptDisclaimer: jest.Mock<Promise<IUsuario | undefined>>;
}

export const mockSoUsuarioUpdateDTO = (): IUsuarioParams => <IUsuarioParams>{};

export interface IUsuarioParams {
  id: number;
  nivel?: number;
  paises?: number[];
  clientes?: number[];
  clientesxdel?: number[];
  puntosServicio?: number[];
  ntfActiva: boolean;
  celular: string;
  ntfContactoActiva: boolean;
  active: boolean;
  codEmail: string;
  codUsuario: string;
  desNombre: string;
  desApellidos: string;
  configurado: boolean;
  roles?: IRol[];
  ntfActivaDesc?: string;
  activeDesc?: string;
}

export interface IUsuarioUpdateDisclaimer {
  acceptDisclaimer: boolean;
}

export interface IUsuarioTableContent {
  items: IUsuario[];
  orderBy?: string;
  sortDesc: boolean;
  pagination: IPaged;
  collapsed: boolean;
  title: string;
  configurado?: boolean;
  totalElements?: number;
}

export const iniUsuarioUpdateDisclaimer: IUsuarioUpdateDisclaimer = {
  acceptDisclaimer: true,
};

export const iniUsuarioParams: IUsuarioParams = {
  id: 0,
  ntfActiva: false,
  celular: "",
  ntfContactoActiva: false,
  active: true,
  codEmail: "",
  codUsuario: "",
  desNombre: "",
  desApellidos: "",
  configurado: true,
  ntfActivaDesc: "",
  activeDesc: "",
};
