import { render, staticRenderFns } from "./PFormInputText.vue?vue&type=template&id=4faa702c&scoped=true"
import script from "./PFormInputText.vue?vue&type=script&lang=ts"
export * from "./PFormInputText.vue?vue&type=script&lang=ts"
import style0 from "./PFormInputText.vue?vue&type=style&index=0&id=4faa702c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4faa702c",
  null
  
)

export default component.exports