
import { Component, Prop, Vue } from "vue-property-decorator";
import { ICliente, IConfColumnaPais, IField } from "@/entities";
import { Action, Getter } from "vuex-class";
import { EventBus } from "@/event-bus";
import {
  EDirection,
  IColumnaResponse,
  IDocumentacionImportacionCreateParams,
  IDocumentacionImportacionCreateResponse,
  IDocumentacionImportacionParams,
} from "@/services";
import DocumentosImportacionTable from "@/components/documentacion/DocumentosImportacionTable.vue";
import VueFilters from "@/vue-filters";
import { Dictionary } from "vue-router/types/router";
import ImportacionForm from "@/components/documentacion/importacion/ImportacionForm.vue";
import VueMethods from "@/vue-methods";

@Component({
  components: {
    ImportacionForm,
    DocumentosImportacionTable,
  },
  mixins: [VueFilters, VueMethods],
})
export class ByImportacionComponent extends Vue {
  @Prop({ required: true, default: {} }) columnsConfig!: IColumnaResponse;
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  @Action("saveDocumentacionImportacion") saveDocumentacionResponse!: (
    params: IDocumentacionImportacionCreateParams
  ) => Promise<IDocumentacionImportacionCreateResponse | undefined>;

  actionsFloat = [{ action: "importarNewDocumento", label: this.$t("label.importar") }];

  verModal = false;
  titleModal = "";
  verImportarForm = false;

  private queryParams = this.$route.query;
  public params: IDocumentacionImportacionParams = {
    page: 1,
    size: 10,
    orderBy: this.columnsConfig.codOrden ?? "id",
    direction:
      this.columnsConfig.codTipoOrden === EDirection.ASC ? EDirection.ASC : EDirection.DESC,
    pkCliente: this.queryParams.pkCliente?.toString(),
    documentTitle: this.queryParams.documentTitle?.toString(),
    documentType: this.queryParams.documentType?.toString(),
    delegaciones: this.$route.query.delegaciones
      ? this.$route.query.delegaciones.toString().split(",")
      : [],
    fechaArchivoInicio: this.getStartDate(this.queryParams.fechaArchivoInicio?.toString()),
    fechaArchivoFin: this.getEndDate(this.queryParams.fechaDocumentoFin?.toString()),
    fechaSubidaInicio: this.getStartDate(this.queryParams.fechaArchivoInicio?.toString()),
    fechaSubidaFin: this.getEndDate(this.queryParams.fechaDocumentoFin?.toString()),
    aprobadoPor: this.queryParams.aprobadoPor?.toString(),
    usuario: this.queryParams.usuario?.toString(),
  };

  beforeMount(): void {
    this.params.pkCliente = this.clienteSelected?.id?.toString() ?? this.params.pkCliente;
  }

  mounted(): void {
    this.$router
      .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
      .catch(() => {
        // Do nothing
      });
  }

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    const columnas: IField[] = [
      {
        key: "actions-left",
        label: this.$t("label.acciones"),
        sortable: false,
        thClass: "table-header table-header-active ",
        tdClass: "table-cell-first",
        visible: true,
        class: "table-cell",
        aux: true,
      },
    ];

    this.columnsConfig?.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna),
        sortable: column.sortable,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        sortKey: column.sortKey,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });

    columnas.push({
      key: "add-columns",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas",
      tdClass: "table-cell table-add-columns",
      visible: true,
      aux: true,
    });

    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  actionModal(action: string): void {
    if (action == "importarNewDocumento") {
      this.verModal = true;
      this.titleModal = this.$t("label.importar").toString();
      this.verImportarForm = true;
    }
  }

  async action(action: { action: string; data?: any }): Promise<void> {
    if (action.action == "importacionConfirm") {
      this.verModal = false;
      const params: IDocumentacionImportacionCreateParams = {
        files: action.data,
      };

      this.saveDocumentacionResponse(params).then(
        async (response?: IDocumentacionImportacionCreateResponse) => {
          if (response) {
            const cantidadDocumentosConError = response.cantidadDocumentosConError;
            const cantidadDocumentosImportados = response.cantidadDocumentosImportados;
            if (cantidadDocumentosConError === 0) {
              this.$swal({
                showLoaderOnDeny: true,
                showCloseButton: true,
                icon: "success",
                title: this.$t("documentos.importacion.success.title"),
                html: this.$t("documentos.importacion.success.description"),
              });
              EventBus.$emit("update-documentos-importacion-table");
            } else {
              let cuerpoMensaje = this.$t("documentos.importacion.failure.description");
              cuerpoMensaje = cuerpoMensaje.replace(
                "${0}",
                cantidadDocumentosImportados.toString()
              );
              cuerpoMensaje = cuerpoMensaje.replace("${1}", cantidadDocumentosConError.toString());
              this.$swal({
                icon: "warning",
                title: this.$t("documentos.importacion.failure.title"),
                html: `${cuerpoMensaje}`,
                showCancelButton: true,
                confirmButtonText: this.$t("label.yes").toString(),
                cancelButtonText: this.$t("label.no").toString(),
              }).then((result) => {
                if (result.isConfirmed && response.fileListaErroresEncoded) {
                  const base64ToBlob = this.convertBase64StringToBlob(
                    response.fileListaErroresEncoded,
                    "text/plain"
                  );
                  this.buildAndDownloadFile(base64ToBlob, "listaErrores");
                }
              });
            }
          } else {
            this.$swal({
              icon: "error",
              title: this.$t("unexpectedError.title"),
              html: this.$t("unexpectedError.description"),
            });
          }
        }
      );
    }
  }
}
export default ByImportacionComponent;
