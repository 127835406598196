import { render, staticRenderFns } from "./SaldosBCRPage.vue?vue&type=template&id=69f9e40d&scoped=true"
import script from "./SaldosBCRPage.vue?vue&type=script&lang=ts"
export * from "./SaldosBCRPage.vue?vue&type=script&lang=ts"
import style0 from "./SaldosBCRPage.vue?vue&type=style&index=0&id=69f9e40d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69f9e40d",
  null
  
)

export default component.exports