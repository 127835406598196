import { FlowReportTypeData } from "@/entities/centros-efectivo-backend/reporte.types";
import { APPLICATION_JSON } from "@/constans";
import { IResponseError } from "@/entities";
import { BACKEND_BASE_URL, IReporteParams, IReporteResponse, serialize } from "@/services";
import { handleResponse } from "@/services/handlers";
import Vue from "vue";
import { IReportesService } from "@/services/saldosonline/reportes/reportes.types";
import { AxiosRequestConfig, AxiosResponse } from "axios";

export class ReportesService implements IReportesService {
  async getFlowReportTypes(): Promise<FlowReportTypeData[] | undefined> {
    const headers: Record<string, string> = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<
      Promise<FlowReportTypeData[] | undefined>,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/configuration/v1/report/flows/types`, {
        headers,
      })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }

  async getFlowsReportTypesForDelivery(): Promise<FlowReportTypeData[] | undefined> {
    const headers: Record<string, string> = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<
      Promise<FlowReportTypeData[] | undefined>,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/configuration/v1/report/flows/types-for-delivery`, {
        headers,
      })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }

  async getFlowsReportTypesOfDelivery(): Promise<FlowReportTypeData[] | undefined> {
    const headers: Record<string, string> = {
      Accept: APPLICATION_JSON,
    };

    const { response, error } = await handleResponse<
      Promise<FlowReportTypeData[] | undefined>,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/configuration/v1/report/flows/types-of-delivery`, {
        headers,
      })
    );
    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }

  async getReportes(params?: IReporteParams): Promise<IReporteResponse | undefined> {
    const { response, error } = await handleResponse<IReporteResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/reporte`, {
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );

    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }

  async downloadReporte(id: number): Promise<AxiosResponse<Blob> | undefined> {
    const postConfig = {
      headers: {
        Accept: "*/*",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;

    const { response, error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/reporte/${id}`, postConfig)
    );

    if (error) {
      return undefined;
    } else {
      return response;
    }
  }

  async deleteReporte(id: number): Promise<void> {
    await handleResponse<Promise<void>, IResponseError>(
      Vue.$axios.delete(`${BACKEND_BASE_URL}/api/reporte/${id}`)
    );
  }

  async getTipos(): Promise<string[] | undefined> {
    const { response, error } = await handleResponse<Promise<string[] | undefined>, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/reporte/tipos`)
    );

    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }

  async getEstados(): Promise<string[] | undefined> {
    const { response, error } = await handleResponse<Promise<string[] | undefined>, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/reporte/estados`)
    );

    if (error) {
      return undefined;
    } else {
      return response?.data;
    }
  }
}
