import { render, staticRenderFns } from "./VideosPlayForm.vue?vue&type=template&id=5856f2e1&scoped=true"
import script from "./VideosPlayForm.vue?vue&type=script&lang=ts"
export * from "./VideosPlayForm.vue?vue&type=script&lang=ts"
import style0 from "./VideosPlayForm.vue?vue&type=style&index=0&id=5856f2e1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5856f2e1",
  null
  
)

export default component.exports