
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { SelectGroupType } from "@/components/look";
import PIcon from "../PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class PFormSelectTranslateItems extends Vue {
  @Prop({ required: true }) value!: string[];

  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
  }

  @Prop({ required: true }) options!: any | SelectGroupType[];
  @Prop({ required: false, default: false }) group!: boolean;
  @Prop({ required: false, default: "value" }) optionValue!: string;
  @Prop({ required: false, default: "text" }) optionText!: string;
  @Prop({ required: false, default: "" }) optionSubText!: string;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: undefined }) rules!: string;
  @Prop({ required: false, default: "" }) invalid_feedback!: string;
  @Prop({ required: false, default: "" }) titleLeft!: string;
  @Prop({ required: false, default: "" }) titleRight!: string;
  @Prop({ required: false, default: true }) sortLeft!: boolean;

  valueLocal: string[] = [];
  selectedLeft: string[] = [];
  selectedRight: string[] = [];

  pushReturnTemp(option: any, returnTemp: any = []): any {
    if (this.group) {
      const itemsReturn: any = [];
      option.items.forEach((item: any) => {
        if (
          !this.valueLocal.find(
            (vl) => JSON.stringify(vl) == JSON.stringify(item[this.optionValue])
          )
        ) {
          itemsReturn.push(item);
        }
      });
      if (itemsReturn.length > 0) {
        returnTemp.push({ name: option.name, items: itemsReturn });
      }
    } else if (
      !this.valueLocal.find((vl) => JSON.stringify(vl) == JSON.stringify(option[this.optionValue]))
    ) {
      returnTemp.push(option);
    }
    return returnTemp;
  }

  get optionsLeft(): any {
    let returnTemp: any = [];
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    if (this.group) {
      this.options = this.options.sort((a: any, b: any) => {
        return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
      });
      this.options.forEach((option: any) => {
        if (option.items.length > 1) {
          option.items = option.items.sort((a: any, b: any) => this.sortOptions(a, b));
        }
      });
    } else if (this.sortLeft) {
      this.options = this.options.sort((a: any, b: any) => this.sortOptions(a, b));
    }
    this.options.forEach((option: any) => {
      returnTemp = this.pushReturnTemp(option, returnTemp);
    });

    //devuelve las opciones que no estan selecionadas
    return returnTemp;
  }

  get optionsRight(): any {
    const returnTemp: any = [];
    this.options.forEach((option: any) => {
      if (this.group) {
        const itemsReturn: any = [];
        option.items.forEach((item: any) => {
          if (
            this.valueLocal.find(
              (vl) => JSON.stringify(vl) == JSON.stringify(item[this.optionValue])
            )
          ) {
            itemsReturn.push(item);
          }
        });
        if (itemsReturn.length > 0) {
          returnTemp.push({ name: option.name, items: itemsReturn });
        }
      } else if (
        this.valueLocal.find((vl) => JSON.stringify(vl) == JSON.stringify(option[this.optionValue]))
      ) {
        returnTemp.push(option);
      }
    });

    //devuelve las opciones que no estan selecionadas
    return returnTemp;
  }

  sortOptions(a: any, b: any): number {
    if (this.optionSubText != "") {
      return a[this.optionText][this.optionSubText].toLowerCase() <
        b[this.optionText][this.optionSubText].toLowerCase()
        ? -1
        : 1;
    } else {
      return a[this.optionText].toLowerCase() < b[this.optionText].toLowerCase() ? -1 : 1;
    }
  }

  moveToRight(): void {
    this.valueLocal = this.valueLocal.concat(this.selectedLeft);
    this.selectedLeft = [];
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }

  moveAllToRight(): void {
    this.valueLocal = [];
    this.options.forEach((option: any) => {
      if (this.group) {
        option.items.forEach((item: any) => {
          this.valueLocal.push(item[this.optionValue]);
        });
      } else {
        this.valueLocal.push(option[this.optionValue]);
      }
    });
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }

  moveToLeft(): void {
    this.selectedRight.forEach((seleRight) => {
      const indexValueLocal = this.valueLocal.findIndex(
        (valueL) => JSON.stringify(valueL) == JSON.stringify(seleRight)
      );
      if (indexValueLocal > -1) {
        this.valueLocal.splice(indexValueLocal, 1);
      }
    });

    this.selectedRight = [];
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }

  moveAllToLeft(): void {
    this.valueLocal = [];
    this.$emit("input", this.valueLocal);
    this.$emit("change", this.valueLocal);
  }
}

export default PFormSelectTranslateItems;
