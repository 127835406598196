import { APPLICATION_JSON } from "@/constans";
import {
  BACKEND_BASE_URL,
  IUsuarioFilter,
  IUsuarioParams,
  IUsuarioResponse,
  IUsuarioService,
  IUsuarioUpdateDisclaimer,
  serialize,
} from "@/services";
import { handleResponse } from "@/services/handlers";
import { IResponseError } from "@/entities/common/common.types";
import { EventBus } from "@/event-bus";
import Vue from "vue";
import { IUsuario } from "@/entities";

export class UsuarioService implements IUsuarioService {
  async _me(): Promise<IUsuario | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IUsuario, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/usuario/me`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-usuario-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
  async _fetch(params: IUsuarioFilter): Promise<IUsuarioResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IUsuarioResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/usuario`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-usuario-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
  async _update(soUsuarioUpdateDTO: IUsuarioParams): Promise<IUsuario | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IUsuario, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/usuario`, soUsuarioUpdateDTO, { headers })
    );

    if (error) {
      EventBus.$emit("on-service-usuario-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async updateAcceptDisclaimer(
    soUsuarioUpdateDTO: IUsuarioUpdateDisclaimer
  ): Promise<IUsuario | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IUsuario, IResponseError>(
      Vue.$axios.patch(`${BACKEND_BASE_URL}/api/usuario/me/accept/disclaimer`, soUsuarioUpdateDTO, {
        headers,
      })
    );

    if (error) {
      EventBus.$emit("on-service-usuario-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
}
