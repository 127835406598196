
import { Component, Vue } from "vue-property-decorator";
import ReportesFilterForm from "./components/ReportesFilterForm.vue";
import ReportesTable from "./components/ReportesTable.vue";
import { EDirection, IColumnaResponse, IPaged, IReporteParams, IReporteResponse } from "@/services";
import VueMethods from "@/vue-methods";
import QuickFilter from "@/pages/videos/components/QuickFilter.vue";
import { Action } from "vuex-class";
import { IReporte, IReporteFilters } from "@/entities";
import { Dictionary } from "vue-router/types/router";
import { SweetAlertResult } from "sweetalert2";

@Component({
  components: {
    ReportesFilterForm,
    ReportesTable,
    QuickFilter,
  },
  mixins: [VueMethods],
})
export class ReportesPage extends Vue {
  @Action("fetchColumna") fetchColumna!: (
    codTabla: string
  ) => Promise<IColumnaResponse | undefined>;

  codTabla = "reportes";
  columnsConfig: IColumnaResponse | null = null;
  mostrarFiltros = false;
  verModal = false;
  queryParams = this.$route.query;
  params: IReporteParams = {
    codUsuario: this.queryParams.codUsuario?.toString(),
    filename: this.queryParams.filename?.toString(),
    tipo:
      this.queryParams.tipo
        ?.toString()
        .split(",")
        .filter((s) => s !== "") ?? [],
    estado:
      this.queryParams.estado
        ?.toString()
        .split(",")
        .filter((s) => s !== "") ?? [],
    fecSolicitud: this.getStartDate(this.queryParams.fecSolicitud?.toString()),
    fecEjecucion: this.getStartDate(this.queryParams.fecEjecucion?.toString()),
  };
  filtersReportes: IReporteFilters = {
    codUsuario: this.params.codUsuario,
    filename: this.params.filename,
    tipo: this.params.tipo,
    estado: this.params.estado,
    fecSolicitud: this.params.fecSolicitud,
    fecEjecucion: this.params.fecEjecucion,
  };
  pagination: IPaged = {
    direction: EDirection.DESC,
    orderBy: "id",
    page: 1,
    size: 10,
  };
  reportes: IReporte[] = [];
  totalElements = 0;
  estados: string[] = [];
  tipos: string[] = [];

  async mounted(): Promise<void> {
    Promise.all([this.getEstados(), this.getTipos()]).then(() => {
      this.fetchReportes();
    });
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersReportes).forEach((key: string) => {
      if (this.filtersReportes[key] != "" && this.filtersReportes[key] != undefined) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros ? `(${numFiltros}) ${this.$t("label.filtros")}` : this.$t("label.filtros");
  }

  async getEstados(): Promise<void> {
    this.estados = (await this.$services.reporte.getEstados()) || [];
  }

  async getTipos(): Promise<void> {
    this.tipos = (await this.$services.reporte.getTipos()) || [];
  }

  async fetchReportes(): Promise<void> {
    this.params.page = this.pagination.page!;
    this.params.size = this.pagination.size;
    this.params.orderBy = this.pagination.orderBy;
    this.params.direction = this.pagination.direction;

    this.$router
      .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
      .catch(() => {
        // Do nothing
      });

    this.params.page = this.pagination.page! - 1;

    await this.$services.reporte
      .getReportes(this.params)
      .then((response: IReporteResponse | undefined) => {
        if (response) {
          this.reportes = response.content;
          this.totalElements = response.totalElements;
        }
      });
  }

  async actions(obj: { action: string; data: any; file?: File }): Promise<void> {
    if (obj.action === "filtrar" || obj.action === "clean") {
      this.params.page = 1;
      this.params.codUsuario = this.filtersReportes.codUsuario;
      this.params.filename = this.filtersReportes.filename;
      this.params.estado = this.filtersReportes.estado;
      this.params.tipo = this.filtersReportes.tipo;
      this.params.fecSolicitud = this.getStartDate(this.filtersReportes.fecSolicitud);
      this.params.fecEjecucion = this.getStartDate(this.filtersReportes.fecEjecucion);

      await this.fetchReportes();
      this.mostrarFiltros = false;
    } else if (obj.action === "change") {
      this.downloadFile(await this.$services.reporte.downloadReporte(obj.data.id));
    } else if (obj.action === "download") {
      this.downloadFile(await this.$services.reporte.downloadReporte(obj.data.id));
    } else if (obj.action === "delete") {
      await this.eliminarReporte(obj.data.id);
    }
  }

  async created(): Promise<void> {
    const response = await this.fetchColumna(this.codTabla);

    this.columnsConfig = response || {
      codTabla: this.codTabla,
      codColumna: "",
    };
  }

  async eliminarReporte(id: number): Promise<void> {
    const { isConfirmed } = await this.modalConfirmRemove();
    if (isConfirmed) {
      await this.$services.reporte.deleteReporte(id);
      await this.fetchReportes();
      this.makeToast("success", this.$t("label.reporte.eliminar.success"));
    }
  }

  async modalConfirmRemove(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.reporte.eliminar.title"),
      html: this.$t("label.reporte.eliminar.message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes"),
      cancelButtonText: this.$t("label.no"),
    });
  }
}

export default ReportesPage;
