<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 10 16">
    <g fill="gray">
      <path
        d="M8.471.84C7.541.298 6.308 0 5.001 0 3.694 0 2.46.298 1.53.84.556 1.406.02 2.174.02 3.002v9.313c0 .828.536 1.595 1.51 2.162.93.541 2.163.84 3.47.84 1.307 0 2.54-.299 3.47-.84.974-.567 1.51-1.334 1.51-2.162V3.002c0-.828-.537-1.596-1.51-2.162zm1.011 11.475c0 .643-.448 1.258-1.262 1.731-.856.498-2 .772-3.22.772-1.22 0-2.363-.274-3.22-.772C.968 13.573.52 12.958.52 12.315v-.229c.25.31.59.593 1.011.839.931.541 2.164.84 3.47.84 1.308 0 2.54-.299 3.471-.84.422-.246.762-.53 1.011-.839v.229zm0-1.552c0 .643-.448 1.257-1.262 1.73-.856.499-2 .773-3.22.773-1.22 0-2.363-.274-3.22-.772C.968 12.02.52 11.406.52 10.763v-.23c.25.31.59.594 1.011.84.931.541 2.164.84 3.47.84 1.308 0 2.54-.299 3.471-.84.422-.246.762-.53 1.011-.84v.23zm0-1.552c0 .642-.448 1.257-1.262 1.73-.856.499-2 .773-3.22.773-1.22 0-2.363-.274-3.22-.772C.968 10.468.52 9.853.52 9.21v-.23c.25.31.59.594 1.011.84.931.54 2.164.84 3.47.84 1.308 0 2.54-.3 3.471-.84.422-.246.762-.53 1.011-.84v.23zm0-1.553c0 .643-.448 1.258-1.262 1.731-.856.499-2 .773-3.22.773-1.22 0-2.363-.274-3.22-.773C.968 8.916.52 8.301.52 7.66v-.23c.25.31.59.594 1.011.839.931.542 2.164.84 3.47.84 1.308 0 2.54-.298 3.471-.84.422-.245.762-.529 1.011-.839v.23zm0-1.552c0 .643-.448 1.258-1.262 1.731-.856.498-2 .773-3.22.773-1.22 0-2.363-.275-3.22-.773C.968 7.364.52 6.75.52 6.107v-.23c.25.31.59.593 1.011.839.931.542 2.164.84 3.47.84 1.308 0 2.54-.298 3.471-.84.422-.246.762-.53 1.011-.839v.23zm0-1.552c0 .643-.448 1.258-1.262 1.731-.856.498-2 .773-3.22.773-1.22 0-2.363-.275-3.22-.773C.968 5.812.52 5.197.52 4.555v-.23c.25.31.59.593 1.011.839.931.541 2.164.84 3.47.84 1.308 0 2.54-.299 3.471-.84.422-.246.762-.53 1.011-.839v.23zm-1.262.179c-.856.498-2 .772-3.22.772-1.22 0-2.363-.274-3.22-.772C.968 4.26.52 3.645.52 3.002S.967 1.744 1.781 1.27C2.637.773 3.78.498 5 .498c1.22 0 2.363.275 3.22.773.813.473 1.261 1.088 1.261 1.73 0 .644-.448 1.258-1.262 1.732z"
      />
      <path
        d="M7.843 1.535C7.066 1.134 6.056.913 5.001.913c-1.056 0-2.065.22-2.843.622C1.38 1.936.933 2.47.933 3.002c0 .53.447 1.066 1.225 1.467s1.787.622 2.843.622c1.055 0 2.065-.22 2.842-.622.778-.401 1.225-.936 1.225-1.467S8.62 1.936 7.843 1.535zm-.228 2.491c-.708.365-1.637.567-2.614.567-.978 0-1.906-.202-2.615-.567-.588-.304-.954-.696-.954-1.024 0-.328.366-.72.954-1.024.709-.366 1.637-.567 2.615-.567.977 0 1.906.201 2.614.567.589.303.954.696.954 1.024 0 .328-.365.72-.954 1.024z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconMonedas",
};
</script>
