
import { Component, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { ISaldosQuickFilter } from "@/pages/saldos/types";
import { mapGetters } from "vuex";
import QuickFilter from "../common/QuickFilter.vue";
import ColapsableEntradasTable from "./ColapsableEntradasTable.vue";
import SaldosEntradasFiltersForm from "./SaldosEntradasFiltersForm.vue";
import { IColumnaResponse, IMovimientoParams } from "@/services";
import Vue from "vue";
import { Dictionary } from "vue-router/types/router";
import { ICliente } from "@/entities";
import VueMethods from "@/vue-methods";
import { Locale } from "@/store";
import { SweetAlertResult } from "sweetalert2";
import { FlowReportTypeData } from "@/entities/centros-efectivo-backend/reporte.types";

@Component({
  name: "entradas",
  components: {
    ColapsableEntradasTable,
    SaldosEntradasFiltersForm,
    QuickFilter,
  },
  mixins: [VueMethods],
  computed: {
    ...mapGetters(["getClienteXDivisa"]),
  },
})
export class SaldosEntradas extends Vue {
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;
  @Getter("getSaldosPageQuickFilter") getSaldosPageQuickFilter!: ISaldosQuickFilter;
  @Getter("getLocale") locale!: Locale;

  @Prop({ required: true, default: {} }) entradasConfig!: IColumnaResponse;
  @Prop({ required: false, default: false }) isBCR!: boolean;
  @Prop() public params!: IMovimientoParams;

  mostrarFiltros = false;

  filtersSaldos: any = {
    tipoFechaSelected: null,
    fechaInicio: null,
    fechaFin: null,
    delegationsSelected: [],
    puntosSelected: [],
  };

  beforeMount(): void {
    this.params.pkCliente = this.clienteSelected?.id ?? this.params.pkCliente;
    this.params.idioma = this.locale;

    const fechaInicio =
      this.params.fecMovimientoInicio ??
      this.params.fecServicioInicio ??
      this.params.fecContableInicio ??
      this.params.fecEntregaInicio ??
      this.params.fecCertificadoInicio;

    const fechaFin =
      this.params.fecMovimientoFin ??
      this.params.fecServicioFin ??
      this.params.fecContableFin ??
      this.params.fecEntregaFin ??
      this.params.fecCertificadoFin;

    const tipoFechaSelected = this.getTipoFechaSelected();

    switch (tipoFechaSelected) {
      case "CONTABLE":
        this.params.fecContableInicio = fechaInicio;
        this.params.fecContableFin = fechaFin;
        this.params.fecServicioInicio = undefined;
        this.params.fecServicioFin = undefined;
        this.params.fecMovimientoInicio = undefined;
        this.params.fecMovimientoFin = undefined;
        break;
      case "SERVICIO":
        this.params.fecServicioInicio = fechaInicio;
        this.params.fecServicioFin = fechaFin;
        this.params.fecContableInicio = undefined;
        this.params.fecContableFin = undefined;
        this.params.fecMovimientoInicio = undefined;
        this.params.fecMovimientoFin = undefined;
        break;
      case "MOVIMIENTO":
        this.params.fecMovimientoInicio = fechaInicio;
        this.params.fecMovimientoFin = fechaFin;
        this.params.fecServicioInicio = undefined;
        this.params.fecServicioFin = undefined;
        this.params.fecContableInicio = undefined;
        this.params.fecContableFin = undefined;
        break;
    }
    this.params.fecCertificadoInicio = undefined;
    this.params.fecCertificadoFin = undefined;
    this.params.fecEntregaInicio = undefined;
    this.params.fecEntregaFin = undefined;

    this.filtersSaldos = {
      tipoFechaSelected: this.getTipoFechaSelected(),
      fechaInicio: fechaInicio ? new Date(fechaInicio) : undefined,
      fechaFin: fechaFin ? new Date(fechaFin) : undefined,
      delegationsSelected: this.params.pkDelegacion ?? [],
      puntosSelected: this.params.fkPuntoServ ?? [],
    };
  }

  async mounted(): Promise<void> {
    this.$router
      .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
      .catch(() => {
        // Do nothing
      });

    this.flowReportTypes = (await this.$services.reporte.getFlowsReportTypesForDelivery()) ?? [];
  }

  flowReportTypes: FlowReportTypeData[] = [];

  get quickFilter(): ISaldosQuickFilter {
    return this.getSaldosPageQuickFilter;
  }

  get hasDivisasFilter(): boolean {
    return this.quickFilter.divisa.length > 0;
  }

  get hasClientFilterButNoDivisasFilter(): boolean {
    if (this.params?.pkCliente) {
      return true;
    }

    return this.hasDivisasFilter;
  }

  getTipoFechaSelected(): string {
    if (this.params.fecMovimientoInicio || this.params.fecMovimientoFin) {
      return "MOVIMIENTO";
    }
    if (this.params.fecServicioInicio || this.params.fecServicioFin) {
      return "SERVICIO";
    }
    return "CONTABLE";
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersSaldos).forEach((key: string) => {
      if (
        key !== "tipoFechaSelected" &&
        this.filtersSaldos[key] != "" &&
        this.filtersSaldos[key] != null
      ) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros ? `(${numFiltros}) ${this.$t("label.filtros")}` : this.$t("label.filtros");
  }

  resetFilters(delegaciones: []): void {
    this.filtersSaldos.delegationsSelected = delegaciones;
  }

  action(action: { action: string; data?: any }): void {
    if (action.action === "filtrar" || action.action === "clean") {
      this.params.fecContableInicio = undefined;
      this.params.fecContableFin = undefined;
      this.params.fecServicioInicio = undefined;
      this.params.fecServicioFin = undefined;
      this.params.fecMovimientoInicio = undefined;
      this.params.fecMovimientoFin = undefined;
      this.params.fecAlbaranInicio = undefined;
      this.params.fecAlbaranFin = undefined;
      this.params.fecEntregaInicio = undefined;
      this.params.fecEntregaFin = undefined;
      this.params.fecCertificadoInicio = undefined;
      this.params.fecCertificadoFin = undefined;
      switch (this.filtersSaldos.tipoFechaSelected) {
        case "CONTABLE":
          this.params.fecContableInicio = this.getStartDate(this.filtersSaldos.fechaInicio);
          this.params.fecContableFin = this.getEndDate(this.filtersSaldos.fechaFin);
          break;
        case "SERVICIO":
          this.params.fecServicioInicio = this.getStartDate(this.filtersSaldos.fechaInicio);
          this.params.fecServicioFin = this.getEndDate(this.filtersSaldos.fechaFin);
          break;
        case "MOVIMIENTO":
          this.params.fecMovimientoInicio = this.getStartDate(this.filtersSaldos.fechaInicio);
          this.params.fecMovimientoFin = this.getEndDate(this.filtersSaldos.fechaFin);
          break;
      }
      this.params.pkDelegacion = this.filtersSaldos.delegationsSelected ?? undefined;
      this.params.fkPuntoServ = this.filtersSaldos.puntosSelected ?? undefined;
      this.$router
        .replace({
          path: this.$route.path,
          query: { ...this.params } as Dictionary<any>,
        })
        .catch(() => {
          // Do nothing
        });
      this.mostrarFiltros = false;
    }
  }

  async downloadEntradasExcelWithDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.movimientos._downloadEntradasBCR(this.params);
      } else {
        await this.$services.movimientos._downloadEntradas(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async downloadEntradasExcelWithoutDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.movimientos._downloadEntradasWithoutDenominationsBCR(this.params);
      } else {
        await this.$services.movimientos._downloadEntradasWithoutDenominations(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async downloadEntradasPDFWithoutDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.movimientos._downloadEntradasPDFWithoutDenominationsBCR(this.params);
      } else {
        await this.$services.movimientos._downloadEntradasPDFWithoutDenominations(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async downloadEntradasPDFWithDenominations(): Promise<void> {
    const res = await this.confirmDescarga();
    if (res.isConfirmed) {
      if (this.isBCR) {
        await this.$services.movimientos._downloadEntradasPDFBCR(this.params);
      } else {
        await this.$services.movimientos._downloadEntradasPDF(this.params);
      }
      this.makeToast("success", this.$t("label.confirmarDescarga.success"));
    }
  }

  async confirmDescarga(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.confirmarDescarga.title"),
      text: this.$t("label.confirmarDescarga.message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes"),
      cancelButtonText: this.$t("label.no"),
    });
  }

  async onGenerateOrdersReport(type: string): Promise<void> {
    if (type === "EXCEL") {
      this.downloadEntradasExcelWithDenominations();
    } else if (type === "EXCEL_WITHOUT_DENOMINATIONS") {
      this.downloadEntradasExcelWithoutDenominations();
    } else if (type === "PDF_WITHOUT_DENOMINATIONS") {
      this.downloadEntradasPDFWithoutDenominations();
    } else if (type === "PDF") {
      this.downloadEntradasPDFWithDenominations();
    }
  }
}
export default SaldosEntradas;
