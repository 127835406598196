
import { ICliente, IParametro } from "@/entities";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";
import format from "date-fns/format";
import { MAX_RANGE_MONTH_DATE } from "@/constans";

@Component({
  name: "transferencias-exportacion",
  mixins: [VueMethods],
})
export class TransferenciasExportacion extends Vue {
  @Prop({ required: true }) optionsClientes!: ICliente[];
  @Prop({ required: false }) verModalExportar!: boolean;
  @Prop({ required: false }) textActionName!: string;

  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getLocale") locale!: Locale;

  maxRange = MAX_RANGE_MONTH_DATE;
  tiposExportacion: IParametro[] | undefined = [];
  valueExport = {
    clientesSelected: this.getClienteSelected ? [this.getClienteSelected.id] : [],
    tipo:
      this.tiposExportacion && this.tiposExportacion?.length != 0
        ? this.tiposExportacion[0].codParametro
        : "",
    fechaInicio: this.getStartDate(new Date().toISOString()),
    fechaFin: this.getEndDate(new Date().toISOString()),
  };

  async mounted(): Promise<void> {
    await this.obtenerTipos();
    this.resetData();
  }

  resetData(): void {
    if (this.getClienteSelected) {
      this.valueExport.clientesSelected = [this.getClienteSelected.id];
    } else {
      this.valueExport.clientesSelected = [];
    }
    this.valueExport.tipo = "EXCEL";
    this.valueExport.fechaInicio = this.getStartDate(new Date().toISOString());
    this.valueExport.fechaFin = this.getStartDate(new Date().toISOString());
  }

  get optionsTipos(): IParametro[] {
    let tiposExportacionList: IParametro[] = [];
    this.tiposExportacion?.forEach((tipo) => {
      if (tipo.active) tiposExportacionList.push(tipo);
    });
    return tiposExportacionList.sort((a, b) => this.sortAscending(a, b, "desDelegacion"));
  }

  async exportarTransferencias(): Promise<void> {
    const clientesSelected: number[] = (this.valueExport.clientesSelected || []).filter(
      (cliente): cliente is number => cliente !== null && cliente !== undefined && cliente !== -1
    );

    const fechaInicio = this.valueExport.fechaInicio
      ? format(new Date(this.valueExport.fechaInicio), "yyyy-MM-dd")
      : "";
    const fechaFin = this.valueExport.fechaFin
      ? format(new Date(this.valueExport.fechaFin), "yyyy-MM-dd")
      : "";

    if (this.valueExport.tipo == "EXCEL") {
      this.downloadFile(
        await this.$services.transferencias.getExcelTransferencias(
          clientesSelected,
          fechaInicio,
          fechaFin,
          navigator.language.split("-")[0]
        )
      );
    } else if (this.valueExport.tipo == "PDF") {
      this.downloadFile(
        await this.$services.transferencias.getPdfTransferencias(
          clientesSelected,
          fechaInicio,
          fechaFin,
          navigator.language.split("-")[0]
        )
      );
    } else if (this.valueExport.tipo == "TXT") {
      this.downloadFile(
        await this.$services.transferencias.getTxtTransferencias(
          clientesSelected,
          fechaInicio,
          fechaFin,
          navigator.language.split("-")[0]
        )
      );
    }
    this.closeExportar();
  }

  async obtenerTipos(): Promise<void> {
    this.tiposExportacion = await this.$services.transferencias.fetchTiposExportacion();
  }

  closeExportar(): void {
    this.resetData();
    this.$emit("closeExportar");
  }
}
export default TransferenciasExportacion;
