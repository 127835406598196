import { render, staticRenderFns } from "./PProgress.vue?vue&type=template&id=bb5aa5ac&scoped=true"
import script from "./PProgress.vue?vue&type=script&lang=ts"
export * from "./PProgress.vue?vue&type=script&lang=ts"
import style0 from "./PProgress.vue?vue&type=style&index=0&id=bb5aa5ac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb5aa5ac",
  null
  
)

export default component.exports