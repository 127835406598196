import {
  IBilletesFalsosConfig,
  IBilletesFalsosConfigData,
  ICountryConfig,
  ICountryConfigData,
  IDemandaConfig,
  IDemandaConfigData,
  IOfertaConfig,
  IOfertaConfigData,
  IPais,
  ITransferenciasConfig,
  ITransferenciasConfigData,
} from "@/entities";
import { i18n } from "@/i18n";

export class Pais implements IPais {
  codPais: string;
  codPaisCorto: string;
  codTimezone: string;
  descripcion: string;
  hasTasa: boolean;
  admiteAnonimo: boolean;
  id: number;
  soConfig: ICountryConfig;
  dateFormat: string;
  idioma: string;
  intercambioDelegaciones: boolean;
  importCheck: boolean;
  bcr: boolean;
  hasInfoBancaria: boolean;
  diasVisualizarVideos: number;
  descargarVideos: boolean;

  constructor(data: IPais) {
    this.codPais = data.codPais;
    this.codPaisCorto = data.codPaisCorto;
    this.codTimezone = data.codTimezone;
    this.descripcion = i18n.te(`label.country.${data.codPais}`)
      ? i18n.t(`label.country.${data.codPais}`)
      : data.descripcion;
    this.hasTasa = data.hasTasa || false;
    this.admiteAnonimo = data.admiteAnonimo || true;
    this.id = data.id;
    this.soConfig = new CountryConfig(data.soConfig || {});
    this.dateFormat = data.dateFormat;
    this.idioma = data.idioma;
    this.intercambioDelegaciones = data.intercambioDelegaciones;
    this.importCheck = data.importCheck;
    this.bcr = data.bcr;
    this.hasInfoBancaria = data.hasInfoBancaria;
    this.diasVisualizarVideos = data.diasVisualizarVideos;
    this.descargarVideos = data.descargarVideos;
  }
}

export class BilletesFalsosConfig implements IBilletesFalsosConfig {
  readonly Enabled?: boolean;
  constructor(data: IBilletesFalsosConfigData) {
    this.Enabled = data.Enabled || true;
  }
}

class OfertaConfig implements IOfertaConfig {
  readonly ShowInfoBancaria: boolean;
  constructor(data: IOfertaConfigData) {
    this.ShowInfoBancaria = data.ShowInfoBancaria || false;
  }
}
class DemandaConfig implements IDemandaConfig {
  readonly ShowInfoBancaria: boolean;
  constructor(data: IDemandaConfigData) {
    this.ShowInfoBancaria = data.ShowInfoBancaria || false;
  }
}

class TransferenciasConfig implements ITransferenciasConfig {
  readonly Oferta: OfertaConfig;
  readonly Demanda: DemandaConfig;
  constructor(data: ITransferenciasConfigData) {
    this.Oferta = new OfertaConfig(data.Oferta || {});
    this.Demanda = new DemandaConfig(data.Demanda || {});
  }
}

export class CountryConfig implements ICountryConfig {
  readonly BilletesFalsos: BilletesFalsosConfig;
  readonly Transferencias: TransferenciasConfig;

  constructor(data: ICountryConfigData) {
    this.BilletesFalsos = new BilletesFalsosConfig(data.BilletesFalsos || {});
    this.Transferencias = new TransferenciasConfig(data.Transferencias || {});
  }
}
