
import { Component, Prop, Vue } from "vue-property-decorator";
import VueMethods from "@/vue-methods";
import UsuariosTable from "./UsuariosTable.vue";
import UsuarioForm from "./UsuarioForm.vue";
import UsuarioFilterForm from "./UsuarioFilterForm.vue";
import {
  EDirection,
  IColumnaResponse,
  iniUsuarioParams,
  IUsuarioFilter,
  IUsuarioParams,
  IUsuarioResponse,
  IUsuarioTableContent,
} from "@/services";
import { Action } from "vuex-class";
import { IPermiso, IUsuario } from "@/entities";
import { ROLE_CLIENTE, ROLE_DELEGACION, ROLE_PAIS, ROLE_PS } from "@/constans";
import { Dictionary } from "vue-router/types/router";

@Component({
  components: { UsuariosTable, UsuarioForm, UsuarioFilterForm },
  mixins: [VueMethods],
})
export default class AdminUsuariosComponent extends Vue {
  @Prop({ required: true }) columnsConfigUsuario!: IColumnaResponse;
  @Action("fetchUsuarios")
  fetchUsuarios!: (params?: IUsuarioFilter) => Promise<IUsuarioResponse | undefined>;
  @Action("updateUsuario")
  updateUsuario!: (params?: IUsuarioParams) => Promise<IUsuario | undefined>;

  verModal = false;
  usuarioMod: IUsuarioParams = iniUsuarioParams;
  mostrarFiltros = false;
  queryParams = this.$route.query;
  filtersUsuario: IUsuarioFilter = {
    tipoUsuario: this.queryParams.tipoUsuario?.toString() ?? "",
    codEmail: this.queryParams.codEmail?.toString() ?? "",
    codUsuario: this.queryParams.codUsuario?.toString() ?? "",
    nombreApellido: this.queryParams.nombreApellido?.toString() ?? "",
    celular: this.queryParams.celular?.toString() ?? "",
    fechaBaja: this.getStartDate(this.queryParams.fechaBaja?.toString()) ?? "",
    fechaCreacion: this.getStartDate(this.queryParams.fechaCreacion?.toString()) ?? "",
    fechaModificacion: this.getStartDate(this.queryParams.fechaModificacion?.toString()) ?? "",
    ntfActiva: this.queryParams.ntfActiva ? Boolean(this.queryParams.ntfActiva) : false,
    active: this.queryParams.active ? Boolean(this.queryParams.active) : false,
    ntfActivaDesc: this.queryParams.ntfActivaDesc?.toString() ?? "",
    activeDesc: this.queryParams.activeDesc?.toString() ?? "SI",
  };
  usuariosAll: IUsuarioTableContent[] = [];

  action(obj: { action: string; data: IUsuarioParams; dataFilters?: any }): void {
    if (obj.action === "modificar") {
      const params: IUsuarioParams = {
        id: obj.data.id,
        nivel: obj.data.nivel,
        ntfActiva: obj.data.ntfActiva,
        celular: obj.data.celular,
        ntfContactoActiva: obj.data.ntfContactoActiva,
        active: obj.data.active,
        codEmail: obj.data.codEmail,
        codUsuario: obj.data.codUsuario,
        desNombre: obj.data.desNombre,
        desApellidos: obj.data.desApellidos,
        configurado: true,
      };
      if (obj.data.nivel === ROLE_PAIS) {
        params.paises = obj.data.paises;
      } else if (obj.data.nivel === ROLE_CLIENTE) {
        params.clientes = obj.data.clientes;
      } else if (obj.data.nivel === ROLE_DELEGACION) {
        params.clientesxdel = obj.data.clientesxdel;
      } else if (obj.data.nivel === ROLE_PS) {
        params.puntosServicio = obj.data.puntosServicio;
      }
      this.updateUsuario(params).then(() => {
        this.verModal = false;
        this.getUsuarios();
      });
    } else if (obj.action === "filtrar" || obj.action === "clean") {
      this.getUsuarios();
      this.mostrarFiltros = false;
    } else if (obj.action === "changePagination" || obj.action === "sortingChange") {
      const paramsFilter = this.buildFilters(
        obj.dataFilters.pagination.page! - 1,
        obj.dataFilters.pagination.size,
        obj.dataFilters.pagination.orderBy,
        obj.dataFilters.pagination.direction,
        obj.dataFilters.configurado
      );
      this.getUsuariosByFilters(paramsFilter);
    }
  }

  created(): void {
    this.getUsuarios();
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersUsuario).forEach((key: string) => {
      if (this.filtersUsuario[key] != "") {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros ? `(${numFiltros}) ${this.$t("label.filtros")}` : this.$t("label.filtros");
  }

  roleCliente(row: IUsuario, permiso: IPermiso): void {
    if (row.nivel === ROLE_CLIENTE) {
      this.usuarioMod.clientes?.push(permiso.cliente.id!);
    } else {
      if (permiso.cliente?.id && this.usuarioMod.clientes!.indexOf(permiso.cliente.id) < 0) {
        this.usuarioMod.clientes?.push(permiso.cliente.id);
      }
      if (this.usuarioMod.clientesxdel!.indexOf(permiso.clientexdel?.id) < 0) {
        this.usuarioMod.clientesxdel?.push(permiso.clientexdel.id);
      }
      if (row.nivel === ROLE_PS && permiso.puntoservicio) {
        this.usuarioMod.puntosServicio?.push(permiso.puntoservicio.id);
      }
    }
  }

  modificarUsuario(row: IUsuario): void {
    this.usuarioMod = {
      id: row.id,
      nivel: row.nivel,
      ntfActiva: row.ntfActiva,
      celular: row.celular,
      ntfContactoActiva: row.ntfContactoActiva,
      active: row.active,
      desNombre: row.desNombre,
      desApellidos: row.desApellidos,
      codUsuario: row.codUsuario,
      codEmail: row.codEmail,
      paises: [],
      clientes: [],
      clientesxdel: [],
      puntosServicio: [],
      configurado: true,
      roles: row.roles,
    };
    if (row.permisos.length === 0) {
      this.usuarioMod.configurado = false;
    } else {
      row.permisos.forEach((permiso) => {
        if (row.nivel === ROLE_PAIS) {
          this.usuarioMod.paises?.push(permiso.pais.id);
        } else {
          if (this.usuarioMod.paises!.indexOf(permiso.pais.id) < 0) {
            this.usuarioMod.paises?.push(permiso.pais.id);
          }
          this.roleCliente(row, permiso);
        }
      });
    }
    this.verModal = true;
  }

  get usuariosAllComp(): IUsuarioTableContent[] {
    return this.usuariosAll;
  }

  async getUsuarios(): Promise<void> {
    this.usuariosAll = [];
    const configurados = [{ configurado: true }, { configurado: false }];
    configurados.forEach((value) => {
      const params = this.buildFilters(0, 10, "id", EDirection.DESC, value.configurado);

      this.$nextTick(() => {
        this.$router
          .replace({ path: this.$route.path, query: { ...params } as Dictionary<any> })
          .catch(() => {
            // Do nothing
          });
      });

      this.fetchUsuarios(params).then((usuarios) => {
        if (usuarios && usuarios.content.length > 0) {
          const items = {
            items: usuarios.content,
            orderBy: params.orderBy,
            sortDesc: true,
            pagination: {
              totalElements: usuarios.content.length,
              size: params.size,
              page: params.page! + 1,
            },
            collapsed: true,
            title: value.configurado ? this.$t("label.usuarios") : this.$t("label.sinConfigurar"),
            configurado: value.configurado,
            totalElements: usuarios.totalElements,
          };

          if (value.configurado) {
            this.usuariosAll.push(items);
          } else {
            this.usuariosAll.unshift(items);
          }
        }
      });
    });
  }

  private buildFilters(
    page: number,
    size: number,
    orderBy: string,
    direction: EDirection,
    configurado: boolean
  ): IUsuarioFilter {
    return {
      page,
      size,
      orderBy,
      direction,
      configurado,
      tipoUsuario: this.filtersUsuario.tipoUsuario ?? undefined,
      codEmail: this.filtersUsuario.codEmail ?? undefined,
      codUsuario: this.filtersUsuario.codUsuario ?? undefined,
      nombreApellido: this.filtersUsuario.nombreApellido ?? undefined,
      celular: this.filtersUsuario.celular ?? undefined,
      fechaBaja: this.filtersUsuario.fechaBaja ?? undefined,
      fechaCreacion: this.filtersUsuario.fechaCreacion ?? undefined,
      fechaModificacion: this.filtersUsuario.fechaModificacion ?? undefined,
      ntfActiva: this.filtersUsuario.ntfActivaDesc
        ? this.filtersUsuario.ntfActivaDesc === "SI"
        : undefined,
      active: this.filtersUsuario.activeDesc ? this.filtersUsuario.activeDesc === "SI" : undefined,
    };
  }

  async getUsuariosByFilters(filters: IUsuarioFilter): Promise<void> {
    const toModify = this.usuariosAll.find(
      (usuario) => usuario.configurado === filters.configurado
    );

    this.fetchUsuarios(filters).then((usuariosPorConfig) => {
      Object.assign(toModify, {
        items: usuariosPorConfig?.content || [],
        orderBy: filters.orderBy,
        sortDesc: filters.direction === EDirection.DESC,
        pagination: {
          totalElements: usuariosPorConfig?.content.length ?? 0,
          size: filters.size,
          page: filters.page! + 1,
          direction: filters.direction,
        },
        collapsed: true,
        title: filters.configurado ? this.$t("label.usuarios") : this.$t("label.sinConfigurar"),
        configurado: filters.configurado,
        totalElements: usuariosPorConfig?.totalElements ?? 0,
      });
    });
  }
}
