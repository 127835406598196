import { IReportesServiceMock } from "@/services/saldosonline/reportes/reportes.types";

export const mockReportesService = (): IReportesServiceMock => ({
  getFlowReportTypes: jest.fn(),
  getFlowsReportTypesForDelivery: jest.fn(),
  getFlowsReportTypesOfDelivery: jest.fn(),
  getReportes: jest.fn(),
  downloadReporte: jest.fn(),
  deleteReporte: jest.fn(),
  getTipos: jest.fn(),
  getEstados: jest.fn(),
});
