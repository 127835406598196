import { ITransferenciasQuickFilter } from "@/pages/transferencias/types";
import { ISaldosQuickFilter } from "@/pages/saldos/types";
import { Context, IActions, IState, Locale } from "@/store";
import { Store } from "vuex";
import {
  IClienteDenominacionParams,
  IClienteFilter,
  IClienteParams,
  IClienteResponse,
  IColumnaResponse,
  IDemandaParams,
  IDocumentacionImportacionCreateParams,
  IDocumentacionImportacionCreateResponse,
  IDocumentacionParams,
  IDocumentacionResponse,
  IEntradaData,
  IEntradaParams,
  II18n,
  IOfertaParams,
  IPaisParams,
  IPaisResponse,
  ISaldoActualParams,
  ISaldoData,
  ISaldoParams,
  ISalidaData,
  ISalidaParams,
  ITransferenciaFilter,
  ITransferenciaParams,
  ITransferenciasData,
  IUsuarioFilter,
  IUsuarioParams,
  IUsuarioResponse,
  IUsuarioUpdateDisclaimer,
} from "@/services";
import {
  ICliente,
  IClienteAll,
  IConfColumnaPais,
  IConfColumnaParams,
  IDemanda,
  IDenominacion,
  IDetallesSaldo,
  IOferta,
  IOfertaDemanda,
  ISaldo,
  ISoConfColumnasUpdateDTO,
  ISoConfConlumnasPaisUpdate,
  ITransferencia,
  ITransferenciaRelacionar,
  IUsuario,
} from "@/entities";
import { Pais } from "@/entities/centros-efectivo-backend/pais";

export const actions: IActions = {
  async initialize(context: Context): Promise<void> {
    const isLoggedIn = await this.$services.auth.getSignedIn();
    if (isLoggedIn) {
      await Promise.all([context.dispatch("fetchLoggedUser")]).finally(async () => {
        const user: IUsuario | undefined = context.state.loggedUser;
        await Promise.all([
          context.dispatch("fetchClientesAll"),
          context.dispatch("fetchAllDivisas"),
          context.dispatch("fetchCountryConfig", user?.pais?.codPaisCorto),
          context.dispatch("fetchDisclaimer"),
        ]).finally(() => context.commit("SET_SIGNED_IN", true));
      });
      //cargaremos los paisesAdmin

      await this.$services.pais._fetch().then((paisesServer) => {
        context.commit(
          "SET_PAISES",
          paisesServer?.content
            ?.map((pais) => new Pais(pais))
            .sort((a, b) => a.descripcion.localeCompare(b.descripcion))
        );
      });
    }
  },
  setCurrentLocation(context: Context, location: string) {
    context.commit("SET_CURRENT_LOCATION", location);
  },
  setCurrentLocale(context: Context, locale: Locale) {
    context.commit("SET_CURRENT_LOCALE", locale);
  },
  async fetchLoggedUser(context: Context): Promise<void> {
    const res = await this.$services.usuarios._me();
    context.commit("SET_LOGGED_USER", res);
  },
  async fetchDisclaimer(context: Context): Promise<void> {
    const response = await this.$services.disclaimer.getDisclaimer();
    context.commit("SET_DISCLAIMER", response);
  },
  async fetchSaldos(context: Context, params: ISaldoParams): Promise<ISaldoData | undefined> {
    return this.$services.saldos._fetch(params);
  },
  async fetchSaldosBCR(context: Context, params: ISaldoParams): Promise<ISaldoData | undefined> {
    return this.$services.saldos._fetchBCR(params);
  },
  async fetchSaldoActual(
    context: Context,
    params: ISaldoActualParams
  ): Promise<ISaldo | undefined> {
    //return ISaldo with detallesSaldo ordered by denominacion.numValue
    return this.$services.saldos._fetchSaldoActual(params).then((saldo) => {
      saldo?.detallesSaldo.sort((a, b) => compareDetallesSaldo(a, b));

      return saldo;
    });
  },
  async fetchSaldosActuales(
    context: Context,
    params: ISaldoActualParams
  ): Promise<ISaldo[] | undefined> {
    return this.$services.saldos._fetchSaldosActuales(params);
  },
  async fetchSaldoDetalle(context: Context, id: number): Promise<IDetallesSaldo[] | undefined> {
    return this.$services.saldos._fetchDetalle(id);
  },
  setSaldosPageQuickFilter(context: Context, filter: ISaldosQuickFilter): void {
    context.commit("SET_SALDOS_PAGE_QUICK_FILTER", filter);
  },
  async fetchClientesAll(context: Context): Promise<void> {
    const clientes = await this.$services.clientes._fetchClientesAll();

    clientes?.sort((a: IClienteAll, b: IClienteAll) => {
      const fa = a.desCliente.toLowerCase(),
        fb = b.desCliente.toLowerCase();
      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    clientes?.forEach((cliente) => {
      cliente.codDesCliente = cliente.codCliente + " - " + cliente.desCliente;
    });

    context.commit("SET_CLIENTESALL", clientes || []);
    const isAdmin = clientes?.length === 1;
    if (isAdmin) {
      await context.dispatch("setClienteSelected", context.state.clientesAll[0]);
    } else {
      await context.dispatch("setClienteSelected");
    }
  },
  async fetchClienteXDivisa(context: Context, idCliente: number): Promise<void> {
    const divisas = await this.$services.clientes._fetchAllClienteXDivisa(idCliente);
    if (divisas) {
      context.commit("SET_CLIENTE_X_DIVISA", divisas);
    }
  },
  async fetchAllDivisas(context: Context): Promise<void> {
    const divisaResponse = await this.$services.divisas._fetchAllDivisas();

    if (divisaResponse?.content) {
      context.commit("SET_DIVISAS", divisaResponse?.content);
    }
  },
  async logout(): Promise<void> {
    await this.$services.auth.logout();
  },
  async fetchEntradas(
    this: Store<IState>,
    context: Context,
    params: IEntradaParams
  ): Promise<IEntradaData | undefined> {
    return this.$services.movimientos._fetchEntradas(params);
  },
  async fetchEntradasBCR(
    this: Store<IState>,
    context: Context,
    params: IEntradaParams
  ): Promise<IEntradaData | undefined> {
    return this.$services.movimientos._fetchEntradasBCR(params);
  },
  async fetchSalidas(
    this: Store<IState>,
    context: Context,
    params: ISalidaParams
  ): Promise<ISalidaData | undefined> {
    return this.$services.movimientos._fetchSalidas(params);
  },
  async fetchSalidasBCR(
    this: Store<IState>,
    context: Context,
    params: ISalidaParams
  ): Promise<ISalidaData | undefined> {
    return this.$services.movimientos._fetchSalidasBCR(params);
  },
  async fetchColumna(
    this: Store<IState>,
    context: Context,
    codTabla: string
  ): Promise<IColumnaResponse | undefined> {
    const response = await this.$services.columna._fetch(codTabla);

    context.commit("SET_COLUMNAS", { codTabla: codTabla, columnas: response });

    return response;
  },
  async saveColumna(
    this: Store<IState>,
    context: Context,
    params: ISoConfColumnasUpdateDTO
  ): Promise<IColumnaResponse | undefined> {
    return this.$services.columna._save(params);
  },
  async fetchCliente(
    this: Store<IState>,
    context: Context,
    params: IClienteFilter
  ): Promise<IClienteResponse | undefined> {
    return this.$services.clientes._fetchClientes(params);
  },
  async saveCliente(
    this: Store<IState>,
    context: Context,
    params: IClienteParams
  ): Promise<ICliente | undefined> {
    return this.$services.clientes._saveCliente(params);
  },
  async fetchUsuarios(
    this: Store<IState>,
    context: Context,
    params: IUsuarioFilter
  ): Promise<IUsuarioResponse | undefined> {
    return this.$services.usuarios._fetch(params);
  },
  async fetchPais(
    this: Store<IState>,
    context: Context,
    params?: IPaisParams
  ): Promise<IPaisResponse | undefined> {
    if (params) {
      return this.$services.pais._fetch(params);
    } else {
      return this.$services.pais._fetch();
    }
  },
  async updateUsuario(
    this: Store<IState>,
    context: Context,
    params: IUsuarioParams
  ): Promise<IUsuario | undefined> {
    return this.$services.usuarios._update(params);
  },
  async fetchCountryConfig(context: Context, code: string): Promise<void> {
    const res = await this.$services.pais.getConfig(code);
    context.commit("SET_COUNTRY_CONFIG", res);
  },
  async fetchI18n(): Promise<II18n | undefined> {
    return this.$services.i18n.getI18n();
  },
  async fetchDocumentacion(
    this: Store<IState>,
    context: Context,
    params: IDocumentacionParams
  ): Promise<IDocumentacionResponse | undefined> {
    return this.$services.documentacion._fetch(params);
  },
  async fetchConfColumnasPais(
    context: Context,
    params: IConfColumnaParams
  ): Promise<IConfColumnaPais[] | undefined> {
    return this.$services.columna._fetchConfColumnasPais(params.codPais, params.codTabla);
  },
  async saveColumnaConfPais(
    this: Store<IState>,
    context: Context,
    params: ISoConfConlumnasPaisUpdate
  ): Promise<IColumnaResponse | undefined> {
    return this.$services.columna._saveConfColumnasPais(params);
  },
  async fetchPropias(
    this: Store<IState>,
    context: Context,
    params: ITransferenciaFilter
  ): Promise<ITransferenciasData | undefined> {
    return this.$services.transferencias.fetchPropias(params);
  },
  setTransferenciasPageQuickFilter(context: Context, filter: ITransferenciasQuickFilter): void {
    context.commit("SET_TRANSFERENCIAS_PAGE_QUICK_FILTER", filter);
  },
  async fetchPublicas(
    this: Store<IState>,
    context: Context,
    params: ITransferenciaFilter
  ): Promise<ITransferenciasData | undefined> {
    return this.$services.transferencias.fetchPublicas(params);
  },
  async fetchByOferta(
    this: Store<IState>,
    context: Context,
    id: number
  ): Promise<ITransferencia[] | undefined> {
    return this.$services.transferencias.fetchByOferta(id);
  },
  async fetchByDemanda(
    this: Store<IState>,
    context: Context,
    id: number
  ): Promise<ITransferencia[] | undefined> {
    return this.$services.transferencias.fetchByDemanda(id);
  },
  async rejectTransferencia(
    this: Store<IState>,
    context: Context,
    id: number
  ): Promise<ITransferencia | undefined> {
    return this.$services.transferencias.rejectTransferencia(id);
  },
  async cancelDemandaPrivada(
    this: Store<IState>,
    context: Context,
    id: number
  ): Promise<ITransferencia | undefined> {
    return this.$services.transferencias.cancelDemandaPrivada(id);
  },
  async aceptarDemandaPrivada(
    this: Store<IState>,
    context: Context,
    params: ITransferenciaParams
  ): Promise<ITransferencia | undefined> {
    return this.$services.transferencias.aceptarDemandaPrivada(params);
  },
  async fetchDenominacionesCliente(
    this: Store<IState>,
    context: Context,
    params: IClienteDenominacionParams
  ): Promise<IDenominacion[] | undefined> {
    return this.$services.clientes._fetchDenominacionesCliente(params);
  },
  async fetchTiposCoste(this: Store<IState>, context: Context): Promise<void> {
    const res = await this.$services.transferencias.fetchTiposCoste();
    context.commit("SET_COSTES", res);
  },
  async setClienteSelected(context: Context, cliente?: ICliente): Promise<void> {
    if (!cliente) {
      context.commit("SET_CLIENTE_X_DIVISA", context.state.divisas);
      context.commit("SET_DELEGACION_X_CLIENTE", []);
      context.commit("SET_CLIENTE_SELECTED");
    } else if (cliente.id !== context.state.clienteSelected?.id) {
      if (context.state.clienteSelected) {
        context.commit("SET_CLIENTE_SELECTED", cliente); //Se guarda el cliente seleccionado sin detalles
      }
      const clienteDetail = await this.$services.clientes._fetchCliente(cliente.id!);

      if (clienteDetail) {
        clienteDetail.codDesCliente = clienteDetail.codCliente + " - " + clienteDetail.desCliente;
      }

      context.commit("SET_CLIENTE_SELECTED", clienteDetail); //Se actualiza el cliente seleccionado con detalles
      context.commit("SET_CLIENTE_X_DIVISA", clienteDetail?.divisas || []);
      context.commit(
        "SET_DELEGACION_X_CLIENTE",
        clienteDetail?.clientexdels
          ?.map((cxd) => cxd.delegacion)
          .sort((a, b) => a.desDelegacion.localeCompare(b.desDelegacion)) || []
      );
    }
  },

  async saveDemanda(
    this: Store<IState>,
    context: Context,
    params: IDemandaParams
  ): Promise<IDemanda | undefined> {
    return this.$services.demandas.saveUpdateDemanda(params);
  },

  async saveOferta(
    this: Store<IState>,
    context: Context,
    params: IOfertaParams
  ): Promise<IOferta | undefined> {
    return this.$services.ofertas.saveUpdateOferta(params);
  },
  async cancelOferta(
    this: Store<IState>,
    context: Context,
    idOferta: number
  ): Promise<IOferta | undefined> {
    return this.$services.ofertas.cancelOferta(idOferta);
  },
  async cancelDemanda(
    this: Store<IState>,
    context: Context,
    idDemanda: number
  ): Promise<IDemanda | undefined> {
    return this.$services.demandas.cancelDemanda(idDemanda);
  },
  async fetchClientesXDelegacion(
    context: Context,
    idDelegacion: number
  ): Promise<ICliente[] | undefined> {
    return this.$services.clientes._fetchAllClienteXDelegacion(idDelegacion);
  },
  async fetchDemandasCompatibles(
    this: Store<IState>,
    context: Context,
    id: number
  ): Promise<IOfertaDemanda[] | undefined> {
    return this.$services.demandas.demandasCompatibles(id);
  },
  async fetchOfertasCompatibles(
    this: Store<IState>,
    context: Context,
    id: number
  ): Promise<IOfertaDemanda[] | undefined> {
    return this.$services.ofertas.ofertasCompatibles(id);
  },
  async relacionarConDemanda(
    this: Store<IState>,
    context: Context,
    params: ITransferenciaRelacionar
  ): Promise<ITransferencia | undefined> {
    return this.$services.transferencias.relacionarConDemanda(params);
  },
  async updateAcceptDisclaimer(
    this: Store<IState>,
    context: Context,
    soUsuarioUpdateDTO: IUsuarioUpdateDisclaimer
  ): Promise<IUsuario | undefined> {
    return this.$services.usuarios.updateAcceptDisclaimer(soUsuarioUpdateDTO);
  },
  async saveDocumentacionImportacion(
    this: Store<IState>,
    context: Context,
    params: IDocumentacionImportacionCreateParams
  ): Promise<IDocumentacionImportacionCreateResponse | undefined> {
    return this.$services.documentacion.saveDocumentacionImportacion(params);
  },
};

const compareDetallesSaldo = (a: IDetallesSaldo, b: IDetallesSaldo) => {
  const numValueA = a.calidadDenominacion?.denominacion?.numValue ?? 0;
  const numValueB = b.calidadDenominacion?.denominacion?.numValue ?? 0;
  return numValueB - numValueA;
};
