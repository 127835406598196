
import { Action, Getter } from "vuex-class";
import { EventBus } from "@/event-bus";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDemandaParams, ISaldoActualParams } from "@/services";
import { Locale } from "@/store";
import VueFilters from "@/vue-filters";

import { ICliente, IDelegacion, IDetalleOferta, IDetallesSaldo, IDivisa, ISaldo } from "@/entities";
import { IOfertaDemanda } from "@/entities/centros-efectivo-backend/ofertaDemanda.types";
import DetalleDemanda from "@/pages/transferencias/common/OfertaDemandaDetalle.vue";
import VueMethods from "@/vue-methods";

@Component({
  name: "demanda-privada-modal",
  components: {
    DetalleDemanda,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [VueFilters, VueMethods],
})
export class DemandarSobreOferta extends Vue {
  @Prop({ required: true }) oferta!: IOfertaDemanda;
  @Prop({ required: true }) divisa!: IDivisa;

  @Action("fetchSaldoActual") fetchSaldoActual!: (
    params?: ISaldoActualParams
  ) => Promise<ISaldo | undefined>;
  @Getter("getLocale") locale!: Locale;
  @Getter("getClienteSelected") getClienteSelected!: ICliente;

  isConfirm = false;
  saldoActual: ISaldo | null = null;
  detallesSaldo: IDetallesSaldo[] | undefined = [];
  saldoDisponible: number | undefined = 0;
  demandaParams: IDemandaParams = {
    numCantidad: 0,
    fecExpirar: new Date(),
    checkAnonimo: false,
    checkParcial: false,
    coste: 0,
    pkDivisa: 0,
    pkCliente: 0,
    pkDelegacion: 0,
    detallesDemanda: [],
    pkDelegaciones: [],
  };
  minFecExpirar: Date = new Date();
  horaExpiracion = "17:00";
  clientes: ICliente[] = [];
  isClientesLoaded = false;

  async mounted(): Promise<void> {
    const horaHoyAlas17 = new Date();
    horaHoyAlas17.setHours(17);
    horaHoyAlas17.setMinutes(0);
    const hoy = new Date();
    if (hoy.getTime() < horaHoyAlas17.getTime()) {
      this.demandaParams.fecExpirar = horaHoyAlas17;
    } else {
      this.demandaParams.fecExpirar = new Date(horaHoyAlas17.getTime() + 86400000);
    }
    await this.fetchClientesByDivisa();

    if (this.clienteSelected) {
      this.demandaParams.checkAnonimo = false;
      this.demandaParams.checkParcial = false;

      this.demandaParams.pkOferta = this.oferta.id;
      this.demandaParams.coste = this.oferta.coste?.id;
      this.demandaParams.pkDelegacion = this.oferta.clientexdel.delegacion.id;
      this.demandaParams.pkDivisa = this.divisa.id;
      this.demandaParams.numTasa = this.oferta.numTasa;
      this.demandaParams.detallesDemanda = [];

      await this.searchSaldosActuales();
    }
  }

  async fetchClientesByDivisa(): Promise<void> {
    let idDelegacion = this.oferta.clientexdel.delegacion.id;
    let idDivisa = this.divisa.id;

    const res = await this.$services.clientes._fetchAllClienteXDelegacionAndDivisa(
      idDelegacion,
      idDivisa
    );
    this.clientes =
      res?.filter(
        (cliente) => cliente.id != this.oferta.clientexdel.cliente.id && cliente.active
      ) ?? [];

    this.demandaParams.pkCliente =
      this.clientes.find((c) => c.id === this.getClienteSelected?.id)?.id ?? this.clientes[0].id!;
    this.isClientesLoaded = true;
  }

  get clienteSelected(): ICliente | null {
    return this.clientes.find((cliente) => cliente.id === this.demandaParams.pkCliente) ?? null;
  }

  get delegaciones(): IDelegacion[] {
    return (
      this.oferta.ubicaciones
        ?.map((ubicacion) => ubicacion.delegacion)
        .filter((delegacion) => delegacion.active)
        .sort((a, b) => this.sortAscending(a, b, "desDelegacion")) ?? []
    );
  }

  get textoTituloConfirm(): string {
    return this.$t("title.demanda.confirm") + "";
  }

  get fields_PTableSimple(): { key: string; label: string }[] {
    if (this.oferta.clientexdel?.cliente?.fakeCerts) {
      return [
        { key: "denominacion", label: this.$t("label.denominacion") },
        { key: "calidadDenominacion", label: this.$t("label.ofertado") },
      ];
    } else {
      return [
        { key: "denominacion", label: this.$t("label.denominacion") },
        { key: "saldoActual", label: this.$t("label.disponible") },
        { key: "calidadDenominacion", label: this.$t("label.demandado") },
      ];
    }
  }

  get saldoRestante(): number {
    return Number(this.saldoDisponible) - Number(this.saldoOfertar);
  }

  get saldoOfertar(): number {
    let sumaImportes = 0;
    this.detallesSaldo?.forEach((detalleSaldo) => {
      if (detalleSaldo.calidadDenominacion?.importe) {
        sumaImportes = Number(sumaImportes) + Number(detalleSaldo.calidadDenominacion?.importe);
      }
    });

    return sumaImportes;
  }

  async searchSaldosActuales(): Promise<void> {
    this.saldoDisponible = 0;
    this.detallesSaldo = [];

    if (this.clienteSelected && this.demandaParams?.pkDelegacion && this.divisa) {
      if (!this.clienteSelected?.fakeCerts) {
        const res = await this.fetchSaldoActual({
          pkCliente: this.demandaParams?.pkCliente,
          pkDelegacion: this.demandaParams?.pkDelegacion,
          pkDivisa: this.divisa.id,
        });

        if (res) {
          this.saldoActual = res;
          if (this.saldoActual) {
            this.saldoDisponible = this.saldoActual.saldoActual;
            this.oferta?.detallesOferta?.forEach((detalleOferta: IDetalleOferta) => {
              const detallesSaldoQueEstanEnOferta = this.saldoActual?.detallesSaldo?.filter(
                (detalleSaldo: IDetallesSaldo) => {
                  return (
                    detalleSaldo.calidadDenominacion?.id === detalleOferta.soCalidadDenominacion.id
                  );
                }
              );

              detallesSaldoQueEstanEnOferta?.forEach((detallesSaldoOfertados) => {
                detallesSaldoOfertados.saldoMaximoOfertar = detalleOferta.cantidadDisponible;
                this.detallesSaldo?.push(detallesSaldoOfertados);
              });
            });

            this.detallesSaldo?.forEach((detalleSaldo) => {
              this.$set(detalleSaldo.calidadDenominacion!, "importe", 0);
            });
          }
        } else {
          EventBus.$emit("p-toast", { variant: "danger", message: res });
        }
      } else {
        this.oferta?.detallesOferta?.forEach((detalleOferta: IDetalleOferta) => {
          this.detallesSaldo?.push({
            calidadDenominacion: {
              denominacion: detalleOferta.soCalidadDenominacion.denominacion,
              codCalidad: detalleOferta.soCalidadDenominacion.codCalidad,
              desCalidad: detalleOferta.soCalidadDenominacion.desCalidad,
              importe: 0,
              id: detalleOferta.soCalidadDenominacion.id,
              cantidad: 0,
              importeSelected: false,
              cantidadSelected: false,
            },
            saldoActual: 0,
            saldoMaximoOfertar: detalleOferta.cantidadDisponible,
          });
        });

        this.detallesSaldo?.forEach((detalleSaldo) => {
          this.$set(detalleSaldo.calidadDenominacion!, "importe", 0);
        });
      }
    }
  }

  cancel(): void {
    this.$emit("cancel");
    this.resetData();
  }

  resetData(): void {
    this.demandaParams.pkOferta = undefined;
    this.demandaParams.observacionesDemanda = "";
  }

  async demandar(): Promise<void> {
    let totalNumCantidad = 0;
    this.detallesSaldo?.forEach((ds) => {
      if (ds.calidadDenominacion && ds.calidadDenominacion.importe > 0) {
        this.demandaParams?.detallesDemanda?.push({
          calidadDenominacion: ds.calidadDenominacion,
          numCantidad: parseFloat(ds.calidadDenominacion?.importe + ""),
        });
      }
      totalNumCantidad = totalNumCantidad + parseFloat(ds.calidadDenominacion?.importe + "");
    });
    this.demandaParams.numCantidad = totalNumCantidad;

    const fecExpirar = new Date(this.demandaParams.fecExpirar);
    fecExpirar.setTime(fecExpirar.getTime() + fecExpirar.getTimezoneOffset() * 60 * 1000);
    const horaMin: string[] = this.horaExpiracion.split(":");
    fecExpirar.setHours(parseInt(horaMin[0]));
    fecExpirar.setMinutes(parseInt(horaMin[1]));

    this.demandaParams.fecExpirar = fecExpirar;
    this.$emit("action", { action: "demandarSobreOfertaConfirmed", data: this.demandaParams });
  }

  get esFechaHoraCorrectos(): boolean {
    let returnTemp = false;
    const horaMin: string[] = this.horaExpiracion.split(":");
    const fechaComprobar = new Date(this.demandaParams.fecExpirar);
    fechaComprobar.setTime(
      fechaComprobar.getTime() + fechaComprobar.getTimezoneOffset() * 60 * 1000
    ); //ajustamos la hora a la zona horaria del offset

    fechaComprobar?.setHours(parseInt(horaMin[0]));
    fechaComprobar?.setMinutes(parseInt(horaMin[1]));
    fechaComprobar?.setSeconds(0);
    fechaComprobar?.setMilliseconds(0);
    const fechaHoy17 = new Date();
    fechaHoy17.setHours(17);
    fechaHoy17.setMinutes(0);
    fechaHoy17.setSeconds(0);
    fechaHoy17.setMilliseconds(0);
    if (new Date().getTime() < fechaHoy17.getTime()) {
      // comprobamos si hoy es antes de las 17
      if (fechaComprobar && fechaComprobar?.getTime() >= fechaHoy17.getTime()) {
        //comprobamos si la fecha puesta es mayor o igual hoy a las 17
        returnTemp = true;
      }
    } else if (fechaComprobar && fechaComprobar?.getTime() >= fechaHoy17.getTime() + 86400000) {
      //comprobamos que la fecha sea mayor o igual que la de mañana a las 17.00
      returnTemp = true;
    }
    return returnTemp;
  }
  get estaTodoOk(): boolean {
    //revisamos los importes
    let totalNumMax = 0;
    this.detallesSaldo?.forEach((ds) => {
      if (ds.calidadDenominacion) totalNumMax = totalNumMax + parseInt(ds.saldoMaximoOfertar + "");
    });

    let totalNumCantidad = 0;
    this.detallesSaldo?.forEach((ds) => {
      if (ds.calidadDenominacion)
        totalNumCantidad = totalNumCantidad + parseInt(ds.calidadDenominacion?.importe + "");
    });
    return this.esFechaHoraCorrectos && totalNumCantidad > 0 && totalNumCantidad <= totalNumMax;
  }
}
export default DemandarSobreOferta;
