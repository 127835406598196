import { IAuthService } from "@/services/auth/auth.types";
import Vue from "vue";
import { User } from "oidc-client-ts";

export class AuthService implements IAuthService {
  public async getUser(): Promise<User | null | void> {
    return Vue.$auth.getUser().then((user) => {
      if (user?.expired) {
        return Vue.$auth.signinSilent().catch(() => {
          this.signIn();
        });
      }

      return user;
    });
  }

  public async getSignedIn(): Promise<boolean | void> {
    return this.getUser().then((user) => {
      if (user == null) {
        return this.signIn()
          .then((refreshedUser) => refreshedUser != null)
          .catch(() => {
            return false;
          });
      }

      return true;
    });
  }

  public async signIn(): Promise<void> {
    await Vue.$auth.signinRedirect();
  }

  public async logout(): Promise<void> {
    await Vue.$auth.signoutRedirect();
  }
}
