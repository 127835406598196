
import { Component, Prop } from "vue-property-decorator";
import Vue from "vue";
import { ICliente, IDelegacion, IField, IParametro, ISoConfColumnasUpdateDTO } from "@/entities";
import { Action, Getter } from "vuex-class";
import VueFilters from "@/vue-filters";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import QuickFilter from "@/components/documentacion/common/QuickFilter.vue";

import { EDirection, IColumnaResponse, IDocumentacionFajosParams } from "@/services";
import { IconDownload } from "@/components/look";
import {
  IDocumentacionParams,
  IDocumentacionResponse,
} from "@/services/saldosonline/documentacion/documentacion.types";
import {
  IDocumento,
  IDocumentoByDelegacion,
} from "@/entities/centros-efectivo-backend/documento.types";
import DocumentacionTableFilters from "@/components/documentacion/DocumentacionTableFilters.vue";
import format from "date-fns/format";
import VueMethods from "@/vue-methods";
import { Dictionary } from "vue-router/types/router";

@Component({
  components: {
    DocumentacionTableFilters,
    IconDownload,
    SelectColumns,
    QuickFilter,
  },
  mixins: [VueFilters, VueMethods],
})
export default class DocumentosDelegacionTable extends Vue {
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getDelegacionesCliente") delegations!: IDelegacion[];
  @Action("fetchDocumentacion")
  fetchDocumentos!: (params?: IDocumentacionParams) => Promise<IDocumentacionResponse | undefined>;
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  @Prop({ required: false, default: {} })
  fieldsI!: IField[];

  @Prop({ required: true, default: {} })
  params!: IDocumentacionParams;

  public codTable = "documentosxdelegacion";

  private paramsDocFajos: IDocumentacionFajosParams = {
    fechaInicio: this.params.fechaInicio
      ? this.getStartDate(this.params.fechaInicio)
      : this.getStartDate(new Date().toISOString()),
    fechaFin: this.params.fechaFin
      ? this.getEndDate(this.params.fechaFin)
      : this.getEndDate(new Date().toISOString()),
    pkDelegacion: [],
    documentType: this.params.tipoDocumento,
  };

  public filtersDocumentos: any = {
    fechaInicio: this.params.fechaInicio
      ? this.getStartDate(this.params.fechaInicio)
      : this.getStartDate(new Date().toISOString()),
    fechaFin: this.params.fechaFin
      ? this.getEndDate(this.params.fechaFin)
      : this.getEndDate(new Date().toISOString()),
    delegationsSelected: [],
    tipoDocumentoSelected: this.params.tipoDocumento ?? null,
  };

  public mostrarFiltros = false;
  private documentacionResponse?: IDocumentacionResponse;
  private docFajosResponse?: IDocumentacionResponse;
  public documentacionByDelegation?: IDocumentoByDelegacion[] = [];
  public sortDesc = this.params.direction === EDirection.DESC;

  async loadInfo(): Promise<void> {
    const delegacionsSelected = this.delegations?.filter((d) =>
      this.params.delegaciones?.find((code) => code === d.codDelegacionSol)
    );

    this.params.delegaciones = delegacionsSelected.map((d) => d.codDelegacionSol);
    this.paramsDocFajos.pkDelegacion = delegacionsSelected.map((d) => d.id);
    this.filtersDocumentos.delegationsSelected = delegacionsSelected.map((d) => d.id);

    await this.updateTiposDocumentos();
    await this.getDocumentos();
  }

  get items(): IDocumentoByDelegacion[] | undefined {
    return this.documentacionByDelegation;
  }

  async onChangeQuickFilter(): Promise<void> {
    await this.loadInfo();
  }

  onSortingChange(sort: { sortBy: string; sortDesc: boolean }): void {
    if (this.documentacionByDelegation != undefined) {
      this.documentacionByDelegation.sort((a, b) =>
        sort.sortDesc
          ? this.sortDescending(a, b, sort.sortBy)
          : this.sortAscending(a, b, sort.sortBy)
      );
    }
  }

  public get visibleFields(): IField[] {
    return this.fieldsI.filter((item) => item.visible);
  }

  get fields(): IField[] {
    return JSON.parse(JSON.stringify(this.fieldsI));
  }

  set fields(fields: IField[]) {
    this.$emit("update:fieldsI", fields);
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersDocumentos).forEach((key: string) => {
      if (this.filtersDocumentos[key] != "" && this.filtersDocumentos[key] != null) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros ? `(${numFiltros}) ${this.$t("label.filtros")}` : this.$t("label.filtros");
  }

  public tiposDocumentos: IParametro[] | undefined = [];

  async updateTiposDocumentos(): Promise<void> {
    this.tiposDocumentos = await this.$services.documentacion.fetchTiposDocumentosByPais(
      this.getClienteSelected?.pais?.id
    );
    this.params.tipoDocumento = this.tiposDocumentos?.find(
      (tipoDoc) => tipoDoc.codParametro == this.params.tipoDocumento
    )?.codParametro;
    this.filtersDocumentos.tipoDocumentoSelected = this.params.tipoDocumento ?? null;
  }

  getTiposDocumentosList(): IParametro[] | undefined {
    if (
      this.tiposDocumentos != undefined &&
      this.params.tipoDocumento != undefined &&
      this.params.tipoDocumento.length != 0
    ) {
      return this.tiposDocumentos?.filter(
        (tipoDoc) => this.params.tipoDocumento == tipoDoc.codParametro
      );
    } else if (this.tiposDocumentos != undefined) {
      return this.tiposDocumentos;
    }
  }

  get getDelegationList(): IDelegacion[] | undefined {
    let delegationList: IDelegacion[] = [];
    if (this.delegations && this.params.delegaciones?.length) {
      this.params.delegaciones?.forEach((delegationParam) => {
        const delegation = this.delegations.find(
          (delegation) => delegation.codDelegacionSol === delegationParam
        );

        if (delegation) {
          delegationList.push(delegation);
        }
      });
    }

    return delegationList.length ? delegationList : this.delegations;
  }

  mostrarResultadosByDelegacion(): void {
    this.documentacionByDelegation = [];
    this.getDelegationList?.forEach((delegacion) => {
      this.getTiposDocumentosList()?.forEach((tipoDoc) => {
        let documentosExistentes: IDocumento[] = [];
        let consolidados: IDocumento[] = [];
        if (this.documentacionResponse?.listado) {
          documentosExistentes = this.documentacionResponse?.listado.filter(
            (doc) =>
              doc.documentType == tipoDoc.codParametro &&
              doc.codDelegacion == delegacion.codDelegacionSol
          );
        }
        if (this.docFajosResponse?.listado) {
          consolidados = this.docFajosResponse?.listado.filter(
            (doc) =>
              doc.codDelegacion == delegacion.codDelegacionSol &&
              doc.documentId == tipoDoc.codParametro
          );
        }

        const isDocFajo = tipoDoc.codParametro === "FAJATM" || tipoDoc.codParametro === "FAJCEF";
        let documento = {
          idDocumentacion: 0,
          date: "",
          cliente: "",
          puntoServicio: "",
          codigoPS: "",
          codRemesa: "",
          documentId: "",
          documentTitle: "",
          documentType: tipoDoc.codParametro,
          delegacion: delegacion.desDelegacion,
          codDelegacion: delegacion.codDelegacionSol,
          numDocs:
            isDocFajo && delegacion.desDelegacion !== "CONSOLIDADO"
              ? consolidados.length
              : documentosExistentes.length,
          docs: documentosExistentes,
        };
        this.documentacionByDelegation?.push(documento);
      });
    });
  }

  async getDocFajos(): Promise<void> {
    this.paramsDocFajos.pkCliente = this.getClienteSelected.id;
    this.paramsDocFajos.documentType = this.params.tipoDocumento;

    this.docFajosResponse = await this.$services.documentacion.fetchDocFajos(this.paramsDocFajos);
  }

  async getDocumentacion(): Promise<void> {
    this.params.codCliente = this.getClienteSelected.codCliente;

    this.$nextTick(() => {
      this.$router
        .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
        .catch(() => {
          // Do nothing
        });
    });

    this.documentacionResponse = await this.fetchDocumentos(this.params);
  }

  async downloadByDelegation(doc: IDocumentoByDelegacion): Promise<void> {
    const documentos = new Map();
    if (doc.docs) {
      doc.docs?.forEach((doc) => {
        documentos.set(doc.documentId.toString(), doc.documentTitle.toString());
      });
    }
    const result = Object.fromEntries(documentos);
    let response = await this.$services.documentacion.downloadByDelegation(
      doc.codDelegacion,
      doc.documentType,
      result
    );

    if (response) {
      this.downloadFile(response);
    } else {
      this.makeToast("danger", this.$t("label.documentation.download.error"));
    }
  }

  async downloadDocumentosTodos(): Promise<void> {
    if (this.items != undefined) {
      const existenDocumentos = this.items.find((item) => item.numDocs > 0);
      if (existenDocumentos) {
        this.params.page = 1;
        const response = await this.$services.documentacion.downloadAllFiles(this.params);
        if (response) {
          this.downloadFile(response);
        } else {
          this.makeToast("danger", this.$t("label.documentation.download.error"));
        }
      }
    }
  }

  action(action: { action: string }): void {
    if (action.action === "download") {
      alert("download");
    } else if (action.action === "filtrar" || action.action === "clean") {
      this.params.page = 1;
      this.params.fechaInicio = format(new Date(this.filtersDocumentos.fechaInicio), "yyyy-MM-dd");
      this.params.fechaFin = format(new Date(this.filtersDocumentos.fechaInicio), "yyyy-MM-dd");
      this.params.delegaciones =
        this.filtersDocumentos.delegationsSelected.map(
          (pk: number) => this.delegations.find((d) => d.id === pk)?.codDelegacionSol
        ) ?? undefined;
      this.params.tipoDocumento = this.filtersDocumentos.tipoDocumentoSelected ?? undefined;

      this.paramsDocFajos.fechaInicio = format(
        new Date(this.filtersDocumentos.fechaInicio),
        "yyyy-MM-dd"
      );
      this.paramsDocFajos.fechaFin = format(
        new Date(this.filtersDocumentos.fechaFin),
        "yyyy-MM-dd"
      );
      this.paramsDocFajos.pkDelegacion = this.filtersDocumentos.delegationsSelected ?? [];
      this.paramsDocFajos.documentType = this.filtersDocumentos.tipoDocumentoSelected ?? undefined;

      this.mostrarFiltros = false;

      this.getDocumentos();
    }
  }

  async getDocumentos(): Promise<void> {
    await this.getDocFajos();
    await this.getDocumentacion();
    this.mostrarResultadosByDelegacion();
  }
}
