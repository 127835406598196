import { APPLICATION_JSON } from "@/constans";
import {
  BACKEND_BASE_URL,
  IUsuarioxVideoParams,
  IVideoFilter,
  IVideoParams,
  IVideoResponse,
  IVideosService,
  serialize,
} from "@/services";
import { handleResponse } from "@/services/handlers";
import { IParametro, IResponseError } from "@/entities";
import { EventBus } from "@/event-bus";
import Vue from "vue";
import { IVideo } from "@/entities/centros-efectivo-backend/video.types";
import { AxiosProgressEvent, AxiosRequestConfig } from "axios";

export class VideosService implements IVideosService {
  async getVideos(params: IVideoFilter): Promise<IVideoResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IVideoResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/videos`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async getVideo(idVideo: number): Promise<IVideo | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IVideo, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/videos/video/${idVideo}`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async getEstadosVideos(): Promise<IParametro[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IParametro[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/videos/estados`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async getTiposNovedad(): Promise<IParametro[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IParametro[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/videos/tiposNovedad`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async save(
    videoParams: IVideoParams,
    file: File,
    progress: { total: number; parcial: number }
  ): Promise<IVideo | undefined> {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        progress.parcial = progressEvent.loaded;
      },
    };

    const bytes = new TextEncoder().encode(JSON.stringify(videoParams));
    const videoData = new Blob([bytes], {
      type: "application/json;charset=utf-8",
    });

    const formData = new FormData();
    formData.append("videoData", videoData);
    formData.append("file", file);

    const { response, error } = await handleResponse<IVideo, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/videos`, formData, config)
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async saveAcceptData(usuarioxVideoParams: IUsuarioxVideoParams): Promise<IVideo | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IVideo, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/videos/saveAcceptData`, usuarioxVideoParams, {
        headers,
      })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async saveVisionado(usuarioxVideoParams: IUsuarioxVideoParams): Promise<IVideo | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IVideo, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/videos/saveVisionado`, usuarioxVideoParams, {
        headers,
      })
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async downloadInforme(pkVideo: number, idioma: string): Promise<void> {
    const params = {
      pkVideo: pkVideo,
      idioma: idioma,
    };
    const postConfig = {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
      params: params,
    } as AxiosRequestConfig;

    const { response, error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/exportar/getInformeVideo`, postConfig)
    );
    if (error) {
      EventBus.$emit("on-service-propias-error", error.response?.data);
    } else if (response) {
      const blobObj = response.data;
      const url = window.URL.createObjectURL(blobObj);
      const link = document.createElement("a");
      link.href = url;
      let filename = "";
      const disposition = response.headers["content-disposition"];
      if (disposition && disposition.indexOf("attachment") !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        if (matches?.[1]) filename = matches[1].replace(/['"]/g, "");
      }
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  }

  async removeVideo(pkVideo: number): Promise<void> {
    const headers = {
      Accept: "*/*",
    };
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.delete(`${BACKEND_BASE_URL}/api/videos/${pkVideo}`, { headers })
    );
    if (error || response!.status != 200) {
      EventBus.$emit("on-service-usuario-error", error?.response?.data);
      return undefined;
    }
  }
}
