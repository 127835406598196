import { render, staticRenderFns } from "./DocumentosTable.vue?vue&type=template&id=5455e305&scoped=true"
import script from "./DocumentosTable.vue?vue&type=script&lang=ts"
export * from "./DocumentosTable.vue?vue&type=script&lang=ts"
import style0 from "./DocumentosTable.vue?vue&type=style&index=0&id=5455e305&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5455e305",
  null
  
)

export default component.exports