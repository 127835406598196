
import { Vue, Component } from "vue-property-decorator";
import { PButton } from "@/components/look";
import { sanitizeUrl } from "@braintree/sanitize-url";

@Component({
  components: { PButton },
})
export default class CookieBanner extends Vue {
  accepted: boolean = localStorage.getItem("acceptCookies") === "true";
  acceptCookies(): void {
    localStorage.setItem("acceptCookies", "true");
    this.accepted = true;
  }

  get sanitizedUrl(): string {
    return sanitizeUrl(this.$t("global.cookiesPolicy.link"));
  }
}
