import { EventBus } from "@/event-bus";
import { i18n } from "@/i18n";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import Vue, { PluginObject } from "vue";
import { IResponseError } from "@/entities/common/common.types";

// Full config:  https://github.com/axios/axios#request-config

const config: AxiosRequestConfig = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};
const generateAxiosInstance = (axiosRequestConfig: AxiosRequestConfig): AxiosInstance => {
  const instance = axios.create(axiosRequestConfig);

  instance.interceptors.request.use(
    (cfg) => {
      // Do something before request is sent
      return cfg;
    },
    (err) => {
      // Do something with request error
      return Promise.reject(err);
    }
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    (res) => {
      // Do something with response data
      return res;
    },
    (err) => {
      // Do something with response error
      if (typeof err.response === "undefined" && !err["__CANCEL__"]) {
        EventBus.$emit("on-network-error", err);
        Vue.swal({
          title: i18n.t("errorNetwork.title").toString(),
          html: `<ol class='text-left'>
                  <li>${i18n.t("errorNetwork.cause1")}</li>
                  <li>${i18n.t("errorNetwork.cause2")}</li>
                  <li>${i18n.t("errorNetwork.cause3")}</li>
                </ol>`,
          icon: "error",
        });
      } else if (err.response?.status === 401 || err.response?.status === 403) {
        Vue.swal({
          title: i18n.t("error.unauthorized").toString(),
          icon: "error",
        });
      } else if ((err.response?.data as IResponseError).message) {
        const responseError = err.response?.data as IResponseError;

        Vue.swal({
          title: i18n.t(responseError.message).toString(),
          icon: "error",
        });
      }

      return Promise.reject(err);
    }
  );

  return instance;
};

const _axios = generateAxiosInstance(config);
const _axiosNoAuth = generateAxiosInstance(config);
const _axiosSilent = generateAxiosInstance(config);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Plugin: PluginObject<any> = {
  install: (VueConstructor) => {
    VueConstructor.$axios = _axios;
    VueConstructor.$axiosNoAuth = _axiosNoAuth;
    VueConstructor.$axiosSilent = _axiosSilent;
  },
};

Plugin.install = (VueConstructor) => {
  VueConstructor.$axios = _axios;
  VueConstructor.$axiosNoAuth = _axiosNoAuth;
  VueConstructor.$axiosSilent = _axiosSilent;
  window.axios = _axios;
  window.axiosNoAuth = _axiosNoAuth;
  window.axiosSilent = _axiosSilent;
  Object.defineProperties(VueConstructor.prototype, {
    $axios: {
      get() {
        return _axios;
      },
    },
    $axiosNoAuth: {
      get() {
        return _axiosNoAuth;
      },
    },
    $axiosSilent: {
      get() {
        return _axiosSilent;
      },
    },
  });
};

Vue.use(Plugin);

export default Plugin;
