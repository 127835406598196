<template>
  <div class="container">
    <div class="chart">
      <apexchart type="pie" :options="chartOptions" :series="series"></apexchart>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

import VueApexCharts from "vue-apexcharts";

Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
@Component
export default class Pie extends Vue {
  series = [30, 30, 30, 30, 40];
  chartOptions = {
    chart: {
      width: "100%",
      type: "pie",
    },
    labels: ["P20", "P50", "P100", "P200", "P500"],
    theme: {
      monochrome: {
        enabled: true,
        color: "#ffd102",
        shadeTo: "dark",
        shadeIntensity: 0.35,
      },
    },
    colors: ["#ffd102"],

    plotOptions: {
      pie: {
        dataLabels: {
          offset: -10,
        },
      },
    },

    dataLabels: {
      style: {
        fontSize: "12px",
        fontWeight: "bold",
      },
      background: {
        enabled: true,
        foreColor: "#9b9b9b",
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: "#9b9b9b",
      },
      formatter(val, opts) {
        const name = opts.w.globals.labels[opts.seriesIndex];
        return [name];
      },
      dropShadow: {
        enabled: false,
      },
    },
    legend: {
      show: false,
    },
  };
}
</script>

<style lang="scss" scoped>
.container {
  width: 350px;
  height: 350px;
  margin: -20px;
}
</style>

<template>
  <!-- Create a div where the graph will take place -->
  <div id="my_dataviz"></div>
</template>
<script>
import * as d3 from "d3";

export default {
  name: "App",
  data() {
    return {
      gdp: [
        { country: "USA", value: 20.5 },
        { country: "China", value: 13.4 },
        { country: "Germany", value: 4.0 },
        { country: "Japan", value: 4.9 },
        { country: "France", value: 2.8 },
      ],
    };
  },
  mounted() {
    this.generateArc();
  },
  methods: {
    generateArc() {
      var width = 450;
      var height = 450;
      var margin = 40;

      // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.
      var radius = Math.min(width, height) / 2 - margin;

      // append the svg object to the div called 'my_dataviz'
      var svg = d3
        .select("#my_dataviz")
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");

      // Create dummy data
      var data = [
        { country: "P100", value: 8123674.87 },
        { country: "P500", value: 10536298.39 },
        { country: "P200", value: 4632455.24 },
        { country: "P20", value: 5839665.03 },
        { country: "P50", value: 6746453.84 },
      ];

      // set the color scale
      var color = d3
        .scaleOrdinal()
        .domain(data)
        .range(["#ffd102", "#997d01", "#cca701", "#ffe367", "#ffec99"]);

      // Compute the position of each group on the pie:
      var pie = d3.pie().value(function (d) {
        return d.value;
      });
      var data_ready = pie(data);

      // filters go in defs element
      var defs = svg.append("defs");

      // create filter with id #drop-shadow
      // height=130% so that the shadow is not clipped
      var filter = defs.append("filter").attr("id", "drop-shadow").attr("height", "130%");

      // SourceAlpha refers to opacity of graphic that this filter will be applied to
      // convolve that with a Gaussian with standard deviation 3 and store result
      // in blur
      filter
        .append("feGaussianBlur")
        .attr("in", "SourceAlpha")
        .attr("stdDeviation", 4)
        .attr("result", "blur");

      // translate output of Gaussian blur to the right and downwards with 2px
      // store result in offsetBlur
      filter.append("feOffset").attr("in", "blur").attr("result", "offsetBlur");

      // overlay original SourceGraphic over translated blurred opacity by using
      // feMerge filter. Order of specifying inputs is important!
      var feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode").attr("in", "offsetBlur");
      feMerge.append("feMergeNode").attr("in", "SourceGraphic");

      var arcGenerator = d3.arc().innerRadius(0).outerRadius(radius);
      // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
      svg
        .selectAll("whatever")
        .data(data_ready)
        .enter()
        .append("path")
        .attr("d", d3.arc().innerRadius(0).outerRadius(radius))
        .attr("fill", function (d) {
          return color(d.data.country);
        })
        .on("mouseenter", function () {
          d3.select(this)
            .transition()
            .duration(200)
            .attr(
              "d",
              d3
                .arc()
                .innerRadius(0)
                .outerRadius(radius * 1.15)
            )
            .style("filter", "url(#drop-shadow)");
        })
        .on("mouseout", function () {
          d3.select(this)
            .transition()
            .duration(200)
            .attr("d", d3.arc().innerRadius(0).outerRadius(radius))
            .style("filter", null);
        });

      svg
        .append("g")
        .attr("font-family", "sans-serif")
        .attr("font-size", 12)
        .attr("text-anchor", "middle")
        .selectAll("text")
        .data(data_ready)
        .join("text")
        .attr("class", "pie-label")
        .attr("transform", function (d) {
          return "translate(" + arcGenerator.centroid(d) + ")";
        })
        .call((text) =>
          text
            .append("tspan")
            .attr("y", "-0.4em")
            .attr("font-weight", "bold")
            .text((d) => d.data.country)
        )
        .call((text) =>
          text
            .filter((d) => d.endAngle - d.startAngle > 0.25)
            .append("tspan")
            .attr("x", 0)
            .attr("y", "0.7em")
            .attr("fill-opacity", 0.7)
            .text((d) => d.data.value.toLocaleString())
        );
    },
  },
};
</script>
