import { IMovimientosServiceMock } from "@/services";

export const mockMovimientosService = (): IMovimientosServiceMock => ({
  _fetchEntradas: jest.fn(),
  _fetchSalidas: jest.fn(),
  _fetchEntradasBCR: jest.fn(),
  _fetchSalidasBCR: jest.fn(),
  _fetchDetallesEntradaByIdEntrada: jest.fn(),
  _fetchDetallesSalidaByIdSalida: jest.fn(),
  _downloadEntradas: jest.fn(),
  _downloadSalidas: jest.fn(),
  _downloadEntradasBCR: jest.fn(),
  _downloadSalidasBCR: jest.fn(),
  _downloadEntradasWithoutDenominations: jest.fn(),
  _downloadEntradasWithoutDenominationsBCR: jest.fn(),
  _downloadEntradasPDFWithoutDenominations: jest.fn(),
  _downloadEntradasPDFWithoutDenominationsBCR: jest.fn(),
  _downloadEntradasPDF: jest.fn(),
  _downloadEntradasPDFBCR: jest.fn(),
  _downloadSalidasWithoutDenominations: jest.fn(),
  _downloadSalidasWithoutDenominationsBCR: jest.fn(),
  _downloadSalidasPDFBCR: jest.fn(),
  _downloadSalidasPDF: jest.fn(),
  _downloadSalidasPDFWithoutDenominations: jest.fn(),
  _downloadSalidasPDFWithoutDenominationsBCR: jest.fn(),
});
