
import VueFilters from "@/vue-filters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { IField, ITransferencia } from "@/entities";
import { IOfertaDemanda } from "@/entities/centros-efectivo-backend/ofertaDemanda.types";

@Component({
  name: "detalle-transferencia",
  mixins: [VueFilters],
})
export default class TransferenciasCompatibles extends Vue {
  @Prop({ required: true }) ofertaDemanda!: IOfertaDemanda;
  @Prop({ required: true }) tipo!: "OFERTA" | "DEMANDA";
  @Action("fetchDemandasCompatibles") fetchDemandasCompatibles!: (
    id: number
  ) => Promise<IOfertaDemanda[] | undefined>;
  @Action("fetchOfertasCompatibles") fetchOfertasCompatibles!: (
    id: number
  ) => Promise<IOfertaDemanda[] | undefined>;

  @Action("setLoading") setLoading!: (loading: boolean) => void;

  detalles: IOfertaDemanda[] = [];

  get title(): string {
    return this.tipo === "OFERTA"
      ? this.$t("label.demandasCompatibles")
      : this.$t("label.ofertasCompatibles");
  }

  get fields(): IField[] {
    const columns = [
      {
        key: "actions-left",
        label: this.$t("label.acciones"),
        sortable: false,
        thClass: "table-header ml-2",
        tdClass: "table-cell-first actions",
        visible: true,
        class: "table-cell",
        aux: true,
      },
      {
        key: "clientexdel.cliente.desCliente",
        label: this.$t("label.cliente"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        aux: false,
      },
      {
        key: "cantidadDisponible",
        label: this.$t("label.cantidad"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        formatter: (value: number, key: string, item: ITransferencia): string => {
          if (item.estado === "ACEPTADA") return this.filterNumber(item.cantidadTransferida, 2);
          else return this.filterNumber(value, 2);
        },
        aux: false,
      },
      {
        key: "coste.desParametro",
        label: this.$t("label.coste"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        aux: false,
      },
      {
        key: "checkParcial",
        label: this.$t("label.parcial"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        aux: false,
      },
      {
        key: "estado",
        label: this.$t("label.estado"),
        sortable: true,
        thClass: "table-header",
        tdClass: (value: string): string => "table-cell-middle " + value.toLowerCase(),
        visible: true,
        class: "table-cell",
        aux: false,
      },
    ];

    if (this.tipo === "DEMANDA") {
      return columns.filter(function (value) {
        return value.key != "checkParcial";
      });
    }
    return columns;
  }
  constructor() {
    super();
  }
  mounted(): void {
    this.fetch();
  }

  relacionarDemanda(demanda: IOfertaDemanda): void {
    this.$emit("relacionarDemanda", { demandaCompatible: demanda, oferta: this.ofertaDemanda });
  }

  relacionarOferta(oferta: IOfertaDemanda): void {
    this.$emit("relacionarOferta", { ofertaCompatible: oferta, demanda: this.ofertaDemanda });
  }

  async fetch(): Promise<void> {
    if (this.tipo === "OFERTA") {
      const response = await this.fetchDemandasCompatibles(this.ofertaDemanda.id);
      if (response) {
        this.detalles = response;
      }
    } else {
      const response = await this.fetchOfertasCompatibles(this.ofertaDemanda.id);
      if (response) {
        this.detalles = response;
      }
    }
  }
}
