
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import VueFilters from "@/vue-filters";
import DetalleSaldo from "./DetalleSaldo.vue";
import Pagination from "@/components/common/Pagination.vue";
import { IConfColumnaPais, IDivisa, IField, ISaldo, ISoConfColumnasUpdateDTO } from "@/entities";
import { Action } from "vuex-class";
import { EventBus } from "@/event-bus";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import {
  EDirection,
  IColumnaResponse,
  IMovimientoParams,
  IPaged,
  ISaldoData,
  ISaldoParams,
} from "@/services";

@Component({
  name: "saldos-table",
  components: {
    SelectColumns,
    DetalleSaldo,
    Pagination,
  },
  mixins: [VueFilters],
})
export default class ColapsableTodosTable extends Vue {
  @Prop({ required: true }) divisa!: IDivisa;
  @Prop({ required: true }) params!: IMovimientoParams;
  @Prop({ required: false, default: false }) isBCR!: boolean;

  @Watch("params", { deep: true })
  onChangeParams(): void {
    this.pagination.page = 1;
    this.getSaldos();
  }

  @Prop({ required: true }) columnsConfig!: IColumnaResponse;
  @Action("fetchSaldos") fetchSaldosPage!: (
    params: ISaldoParams
  ) => Promise<ISaldoData | undefined>;
  @Action("fetchSaldosBCR") fetchSaldosBCRPage!: (
    params: ISaldoParams
  ) => Promise<ISaldoData | undefined>;
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;
  // Is card table collapsed
  collapsed = true;
  codTable = "saldos";

  sortDesc = this.columnsConfig?.codTipoOrden !== "ASC";
  totalElements = 0;

  pagination: IPaged = {
    direction: this.sortDesc ? EDirection.DESC : EDirection.ASC,
    orderBy: this.columnsConfig?.codOrden ?? "fecCertificado",
    page: 1,
    size: 10,
  };

  saldos: ISaldo[] = [];
  totalSaldoActual = 0;

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [
      {
        key: "actions-left",
        label: this.$t("label.acciones"),
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first actions",
        visible: true,
        class: "table-cell",
        aux: true,
        code: "",
      },
    ];
    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });
    columnas.push({
      key: "actions-right",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas pb-0",
      tdClass: "table-cell-middle actions",
      visible: true,
      class: "table-cell",
      aux: true,
      code: "",
    });
    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  mounted(): void {
    this.getSaldos();
  }

  async getSaldos(): Promise<void> {
    const params: IMovimientoParams = { ...this.params };
    params.pkDivisa = this.divisa.id;
    params.page = this.pagination.page! - 1;
    params.size = this.pagination.size;
    params.orderBy = this.pagination.orderBy;
    params.direction = this.pagination.direction;
    let response: ISaldoData | undefined;
    if (this.isBCR) {
      response = await this.fetchSaldosBCRPage(params);
    } else {
      response = await this.fetchSaldosPage(params);
    }
    if (response) {
      this.saldos = response.saldos.content;
      this.totalSaldoActual = response.totalSaldo;
      this.totalElements = response.saldos.totalElements;
    }
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  onChangeCurrentPage(currentPage: number): void {
    this.pagination.page = currentPage;
    this.getSaldos();
  }

  onSortingChange(): void {
    this.$nextTick(async () => {
      this.pagination.direction = this.sortDesc ? EDirection.DESC : EDirection.ASC;
      await this.getSaldos();
      await this.saveColumnsConfig();
    });
  }

  openModalEntradas(saldo: ISaldo): void {
    EventBus.$emit("open-modal-entradas", saldo);
  }

  openModalSalidas(saldo: ISaldo): void {
    EventBus.$emit("open-modal-salidas", saldo);
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.columnsConfig.codColumna || "",
      codTabla: this.columnsConfig.codTabla,
    };
    if (this.pagination) {
      params.codTipoOrden = this.pagination.direction;
      params.numPagElements = this.pagination.size;
      params.codOrden = this.pagination.orderBy;
    }
    await this.saveColumna(params);
  }
}
