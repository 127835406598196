
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ICliente, IConfColumnaPais, IField, ISoConfColumnasUpdateDTO } from "@/entities";
import { Action } from "vuex-class";
import VueFilters from "@/vue-filters";

import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import {
  EDirection,
  IClienteParams,
  IClienteTableContent,
  IColumnaResponse,
  IPaged,
} from "@/services";
import Pagination from "@/components/common/Pagination.vue";

@Component({
  components: { SelectColumns, Pagination },
  mixins: [VueFilters],
})
export default class ClientesTable extends Vue {
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;
  @Prop({ required: true }) clientes!: IClienteTableContent[];
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  codTable = "clientes";
  sortDesc = this.columnsConfig?.codTipoOrden !== "ASC";

  pagination: IPaged = {
    direction: this.sortDesc ? EDirection.DESC : EDirection.ASC,
    orderBy: this.columnsConfig?.codOrden ?? "id",
    page: 1,
    size: 10,
  };

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [
      {
        key: "actions-left",
        label: this.$t("label.acciones"),
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first actions",
        visible: true,
        class: "table-cell",
        aux: true,
        code: "",
      },
    ];
    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });
    columnas.push({
      key: "actions-right",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas pb-0",
      tdClass: "table-cell-middle actions",
      visible: true,
      class: "table-cell",
      aux: true,
      code: "",
    });
    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  private get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  clickAcciones(accion: string, row: ICliente): void {
    if (accion === "modificar") {
      this.$emit("edit", row);
    }
  }

  clickActivar(row: ICliente): void {
    const delegaciones: number[] = [];
    row.clientexdels.forEach((clienex) => {
      if (clienex.active) {
        delegaciones.push(clienex.delegacion.id);
      }
    });
    const valueRow: IClienteParams = {
      active: !row.active,
      codCliente: row.codCliente,
      desCliente: row.desCliente,
      id: row.id,
      fakeCerts: row.fakeCerts,
      delegaciones: delegaciones,
      pais: row.pais.id,
    };
    this.$emit("activate", { data: valueRow, action: "modificar" });
  }

  onChangeCurrentPage(currentPage: number, clientePais: IClienteTableContent): void {
    const sortDesc = clientePais ? clientePais.sortDesc : true;
    const orderBy = clientePais ? clientePais.orderBy : "id";
    const pagination: IPaged = {
      direction: sortDesc ? EDirection.DESC : EDirection.ASC,
      orderBy,
      page: currentPage,
      size: clientePais.pagination.size,
    };
    this.pagination = pagination;

    this.$emit("change", {
      action: "changePagination",
      dataFilters: {
        codPais: clientePais.codPais,
        idPais: clientePais.idPais,
        pagination,
      },
    });
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.columnsConfig.codColumna || "",
      codTabla: this.columnsConfig.codTabla,
    };
    if (this.pagination) {
      params.codTipoOrden = this.pagination.direction;
      params.numPagElements = this.pagination.size;
      params.codOrden = this.pagination.orderBy;
    }
    await this.saveColumna(params);
  }

  onSortingChange(
    obj: { sortBy: string; sortDesc: boolean },
    clientePais: IClienteTableContent
  ): void {
    this.$nextTick(async () => {
      const sortDesc = clientePais ? clientePais.sortDesc : true;
      const orderBy = clientePais ? clientePais.orderBy : "id";

      const pagination: IPaged = {
        direction: sortDesc ? EDirection.DESC : EDirection.ASC,
        orderBy,
        page: clientePais.pagination.page,
        size: clientePais.pagination.size,
      };
      await this.saveColumnsConfig();
      this.$emit("filter", {
        action: "sortingChange",
        dataFilters: {
          pagination,
          codPais: clientePais.codPais,
          idPais: clientePais.idPais,
        },
      });
    });
  }

  fieldsI: IField[] = [
    {
      key: "actions-left",
      label: this.$t("label.acciones"),
      sortable: false,
      thClass: "table-header table-header-yellow",
      tdClass: "table-cell",
      visible: true,
      aux: true,
    },

    {
      key: "codCliente",
      label: this.$t("label.codCliente"),
      sortable: true,
      thClass: "table-header",
      tdClass: "table-cell",
      visible: true,
      aux: false,
    },
    {
      key: "desCliente",
      label: this.$t("label.cliente"),
      sortable: true,
      thClass: "table-header",
      tdClass: "table-cell",
      visible: true,
      aux: false,
    },
    {
      key: "fecBaja",
      label: this.$t("label.fecBaja"),
      sortable: true,
      thClass: "table-header",
      tdClass: "table-cell",
      visible: true,
      aux: false,
      formatter: (value: string, key: string, item: ICliente): string =>
        this.filterDate(item.fecBaja),
    },
    {
      key: "fecCrear",
      label: this.$t("label.fechaCreacion"),
      sortable: true,
      thClass: "table-header",
      tdClass: "table-cell",
      visible: true,
      aux: false,
      formatter: (value: string, key: string, item: ICliente): string =>
        this.filterDate(item.fecCrear),
    },
    {
      key: "fecModif",
      label: this.$t("label.fechaModificacion"),
      sortable: true,
      thClass: "table-header",
      tdClass: "table-cell",
      visible: true,
      aux: false,
      formatter: (value: string, key: string, item: ICliente): string =>
        this.filterDate(item.fecModif),
    },
    {
      key: "active",
      label: this.$t("label.activo"),
      sortable: true,
      thClass: "table-header",
      tdClass: "table-cell",
      visible: true,
      aux: false,
    },
    {
      key: "add-columns",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas",
      tdClass: "table-cell table-add-columns",
      visible: true,
      aux: true,
    },
  ];
}
