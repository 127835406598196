
import { Component, Prop, Vue } from "vue-property-decorator";
import TransferenciasPropiasFiltersForm from "./TransferenciasPropiasFiltersForm.vue";
import TransferenciasPropiasTable from "./TransferenciasPropiasTable.vue";
import { Action, Getter } from "vuex-class";
import {
  IColumnaResponse,
  iniPaged,
  IPaged,
  ITransferenciaFilter,
  ITransferenciaParams,
  ITransferenciasData,
} from "@/services";
import {
  ICliente,
  IDemanda,
  IDivisa,
  IOferta,
  IOfertaDemanda,
  ITransferencia,
  ITransferenciaRelacionar,
} from "@/entities";
import QuickFilter from "@/pages/transferencias/common/QuickFilter.vue";
import { iniQuickFilter, IQuickFilter } from "@/pages/transferencias/types/quickFilter.types";
import { ITransferenciasAll } from "@/pages/transferencias/types";
import AceptarDemanda from "@/pages/transferencias/propias/AceptarDemanda.vue";
import RelacionarConDemanda from "@/pages/transferencias/propias/RelacionarConDemanda.vue";
import RelacionarConOferta from "@/pages/transferencias/propias/RelacionarConOferta.vue";
import VueMethods from "@/vue-methods";
import TransferenciasExportacion from "@/pages/transferencias/common/TransferenciasExportacion.vue";

@Component({
  mixins: [VueMethods],
  components: {
    RelacionarConOferta,
    RelacionarConDemanda,
    AceptarDemanda,
    TransferenciasPropiasFiltersForm,
    TransferenciasPropiasTable,
    TransferenciasExportacion,
    QuickFilter,
  },
})
export class TransferenciasPropias extends Vue {
  @Prop({ required: true }) propiasOfertasConfig!: IColumnaResponse;
  @Prop({ required: true }) propiasDemandasConfig!: IColumnaResponse;
  @Getter("getClientesAll") getClientesAll!: ICliente[];
  @Getter("getClienteXDivisa") getClienteXDivisa!: IDivisa[];
  @Getter("getDivisas") getDivisas!: IDivisa[];
  @Action("fetchPropias") fetchPropias!: (
    params: ITransferenciaFilter
  ) => Promise<ITransferenciasData | undefined>;
  @Action("cancelOferta") cancelOferta!: (idOferta: number) => Promise<IOferta | undefined>;
  @Action("cancelDemanda") cancelDemanda!: (idDemanda: number) => Promise<IDemanda | undefined>;
  @Action("rejectTransferencia") rejectTransferencia!: (
    id: number
  ) => Promise<ITransferencia | undefined>;
  @Action("cancelDemandaPrivada") cancelDemandaPrivada!: (
    id: number
  ) => Promise<ITransferencia | undefined>;
  @Action("relacionarConDemanda") relacionarConDemanda!: (
    params: ITransferenciaRelacionar
  ) => Promise<ITransferencia | undefined>;
  @Action("aceptarDemandaPrivada") aceptarDemandaPrivada!: (
    params: ITransferenciaParams
  ) => Promise<ITransferencia | undefined>;
  verModalExportar = false;
  titleModalExportar = "";
  confirm: any = {
    show: false,
    action: "",
    title: "",
    description: "",
    data: "",
  };
  verModal = false;
  verAceptarDemanda = false;
  verRelacionarConDemanda = false;
  verRelacionarConOferta = false;
  titleModal = "";
  transferencia: Partial<ITransferencia> = {};
  oferta: Partial<IOfertaDemanda> = {};
  demandaCompatible: Partial<IOfertaDemanda> = {};
  demanda: Partial<IOfertaDemanda> = {};
  ofertaCompatible: Partial<IOfertaDemanda> = {};

  mostrarFiltros = false;
  filters: any = {
    fecCertificadoInicio: null,
    fecCertificadoFin: null,
    delegationsSelected: [],
    allEstados: [],
  };
  quickFilter: IQuickFilter = iniQuickFilter;
  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filters).forEach((key: string) => {
      if (this.filters[key] != "" && this.filters[key] != null) {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros ? `(${numFiltros}) ${this.$t("label.filtros")}` : this.$t("label.filtros");
  }

  params: ITransferenciaFilter = {
    pkCliente: undefined,
    tipo: "",
    delegaciones: undefined,
    estado: undefined,
  };

  transferenciasAll: ITransferenciasAll[] = [];
  optionsClientes: ICliente[] = [];

  verExportar(): void {
    this.verModalExportar = true;
    this.titleModalExportar = this.$t("action.export").toString();
  }

  closeExportar(): void {
    this.verModalExportar = false;
  }

  transferencias(tipo: "OFERTA" | "DEMANDA"): ITransferenciasAll[] {
    let result: ITransferenciasAll[] = [];
    const filtradoPorTipo = this.transferenciasAll.filter((data) => data.tipo === tipo);
    //filtramos por divisas seleccionadas
    this.quickFilter.divisas.forEach((divisa) => {
      const filtradoPorDivisas = filtradoPorTipo.filter((data) => data.divisa.codDivisa === divisa);
      result = result.concat(filtradoPorDivisas);
    });
    return result;
  }

  checkClient(): void {
    if (!this.getClientesAll.find((c) => c.id === Number(this.params.pkCliente))) {
      this.params.pkCliente = undefined;
    }
  }
  async getPropias(
    tipo: "OFERTA" | "DEMANDA",
    divisa: IDivisa,
    pagination: IPaged
  ): Promise<ITransferenciasData | undefined> {
    this.checkClient();
    const params: ITransferenciaFilter = this.params;
    params.pkDivisa = divisa.id;
    params.tipo = tipo;
    params.page = pagination.page! - 1;
    params.size = pagination.size;
    params.orderBy = pagination.orderBy;
    params.direction = pagination.direction;

    return await this.fetchPropias(params);
  }
  async cargarTodosDatos(): Promise<void> {
    this.transferenciasAll = [];
    this.getClienteXDivisa.forEach((divisa) => {
      this.cargarDatos("OFERTA", divisa);
      this.cargarDatos("DEMANDA", divisa);
    });
  }
  async cargarDatos(tipo: "OFERTA" | "DEMANDA", divisa: IDivisa): Promise<void> {
    const transferencias = this.transferenciasAll.find(
      (trans) => trans.tipo === tipo && trans.divisa.codDivisa === divisa.codDivisa
    );
    if (transferencias) {
      const response: ITransferenciasData | undefined = await this.getPropias(
        tipo,
        divisa,
        transferencias.pagination
      );
      if (response) {
        transferencias.transferencias = response.transferencias.content;
        transferencias.pagination.totalElements = response.transferencias.totalElements;
        transferencias.cantidadDisponible = response.cantidadDisponible;
      }
    } else {
      const pagination: IPaged = iniPaged;

      const response: ITransferenciasData | undefined = await this.getPropias(
        tipo,
        divisa,
        pagination
      );

      if (response) {
        pagination.totalElements = response.transferencias.totalElements;
        this.transferenciasAll.push({
          tipo: tipo,
          divisa: divisa,
          transferencias: response.transferencias.content,
          cantidadDisponible: response.cantidadDisponible,
          pagination: pagination,
          totalElements: response.transferencias.totalElements,
        });
      }
    }
  }

  cerrarModales(): void {
    this.verModal = false;
    this.verRelacionarConDemanda = false;
    this.verRelacionarConOferta = false;
    this.verAceptarDemanda = false;
  }

  setConfirm(action: { action: string; data?: any }): void {
    if (action.action === "cancelOferta") {
      this.confirm = {
        show: true,
        action: "cancelOfertaConfirmed",
        title: this.$t("label.cancelarOferta"),
        description: this.$t("label.cancelarOferta.message"),
        data: action.data,
      };
    } else if (action.action === "cancelDemanda") {
      this.confirm = {
        show: true,
        action: "cancelDemandaConfirmed",
        title: this.$t("label.cancelarDemanda"),
        description: this.$t("label.cancelarDemanda.message"),
        data: action.data,
      };
    } else if (action.action === "rechazarDemanda") {
      this.confirm = {
        show: true,
        action: "rechazarDemandaConfirmed",
        title: this.$t("label.rechazarDemanda"),
        description: this.$t("label.rechazarDemanda.message"),
        data: action.data,
      };
    } else if (action.action === "aceptarDemanda") {
      this.verModal = true;
      this.verAceptarDemanda = true;
      this.transferencia = action.data;
      this.titleModal = this.$t("label.aceptarDemanda");
    } else if (action.action === "transferir") {
      this.confirm = {
        show: true,
        action: "transferirConfirmed",
        title: this.$t("label.aceptarDemanda"),
        description: this.$t("label.aceptarDemandaPrivada.message"),
        data: action.data,
      };
    } else if (action.action === "cancelarDemandaPrivada") {
      this.confirm = {
        show: true,
        action: "cancelarDemandaPrivadaConfirmed",
        title: this.$t("label.cancelarDemanda"),
        description: this.$t("label.canelarDemandaPrivada.message"),
        data: action.data,
      };
    }
  }

  async actionConfirmed(action: { action: string; data?: any }): Promise<void> {
    if (action.action === "transferirConfirmed") {
      const response = await this.aceptarDemandaPrivada(this.confirm.data);
      this.confirm.show = false;
      this.cerrarModales();
      if (response) {
        await this.cargarTodosDatos();
      }
    } else if (action.action === "cancelOfertaConfirmed") {
      const response = await this.cancelOferta(this.confirm.data);
      this.confirm.show = false;
      if (response) {
        await this.cargarTodosDatos();
      }
    } else if (action.action === "cancelDemandaConfirmed") {
      const response = await this.cancelDemanda(this.confirm.data);
      this.confirm.show = false;
      if (response) {
        await this.cargarTodosDatos();
      }
    } else if (action.action === "rechazarDemandaConfirmed") {
      const response = await this.rejectTransferencia(this.confirm.data);
      this.confirm.show = false;
      if (response) {
        await this.cargarTodosDatos();
      }
    } else if (action.action === "cancelarDemandaPrivadaConfirmed") {
      const response = await this.cancelDemandaPrivada(this.confirm.data);
      this.confirm.show = false;
      if (response) {
        await this.cargarTodosDatos();
      }
    }
  }

  async actionRelacionar(action: { action: string; data?: any }): Promise<void> {
    if (action.action === "relacionarDemanda") {
      this.verModal = true;
      this.verRelacionarConDemanda = true;
      this.oferta = action.data.oferta;
      this.demandaCompatible = action.data.demandaCompatible;
      this.titleModal = this.$t("label.transferir");
    } else if (action.action === "relacionarOferta") {
      this.verModal = true;
      this.verRelacionarConOferta = true;
      this.demanda = action.data.demanda;
      this.ofertaCompatible = action.data.ofertaCompatible;
      this.titleModal = this.$t("label.demandar");
    } else if (action.action === "relacionarConDemanda") {
      const response = await this.relacionarConDemanda(action.data);
      this.cerrarModales();
      if (response) await this.cargarTodosDatos();
    }
  }

  async actionQuickFilter(action: { action: string; data?: any }): Promise<void> {
    if (action.action === "quickFilter_cliente") {
      if (action.data.cliente == -1) {
        this.params.pkCliente = undefined;
      } else {
        this.params.pkCliente = action.data.cliente;
      }
      await this.cargarTodosDatos();
    } else if (action.action === "quickFilter_divisa") {
      //lo dejo por si hay que hacer alguna accion a futuro
    } else if (action.action === "quickFilter_tipo") {
      //lo dejo por si hay que hacer alguna accion a futuro
    }
  }

  async action(action: { action: string; data?: any }): Promise<void> {
    if (action.action === "print") {
      alert("print");
    } else if (action.action === "download") {
      alert("download");
    } else if (action.action === "filtrar" || action.action === "clean") {
      this.params.page = 1;
      this.params.fecFin = this.filters.fecCertificadoFin
        ? new Date(this.filters.fecCertificadoFin).toISOString()
        : undefined;
      this.params.fecInicio = this.filters.fecCertificadoInicio
        ? new Date(this.filters.fecCertificadoInicio).toISOString()
        : undefined;
      this.params.delegaciones = this.filters.delegationsSelected ?? undefined;
      this.params.estado = this.filters.allEstados ?? undefined;
      await this.cargarTodosDatos();
      this.mostrarFiltros = false;
    } else if (action.action === "changeTable") {
      await this.cargarDatos(action.data.tipo, action.data.divisa);
    }
    await this.actionQuickFilter(action);
    this.setConfirm(action);
    await this.actionConfirmed(action);
    await this.actionRelacionar(action);
  }
  mounted(): void {
    this.getClienteXDivisa.forEach((divisa) => {
      if (this.quickFilter.divisas.findIndex((div) => div === divisa.codDivisa) < 0)
        this.quickFilter.divisas.push(divisa.codDivisa);
    });
    this.optionsClientes = this.getClientesAll.slice();
  }
}
export default TransferenciasPropias;
