
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { format } from "date-fns";
import PFormInputText from "../PFormInput/PFormInputText.vue";
import PIcon from "../PIcon/PIcon.vue";
import { ITranslationsDatepicker, translations } from "./languajesDatepicker";
import { Placement } from "popper.js";

@Component({
  components: {
    PFormInputText,
    PIcon,
  },
})
export class PFormDatepicker extends Vue {
  @Prop({ required: true }) value!: Date;
  @Watch("value")
  onChangeValue(): void {
    this.initDate();
  }

  @Prop({ required: false, default: "es" }) language!: string;
  @Prop({ required: false, default: "dropdown" }) type!: string; // dropdown/flat/
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false }) readonly!: boolean;
  @Prop({ required: false, default: undefined }) state!: boolean;
  @Prop({ required: false, default: undefined }) rules!: string;
  @Prop({ required: false, default: undefined }) invalid_feedback!: string;
  @Prop({ required: false, default: undefined }) startSelector!: Date | undefined;
  @Prop({ required: false, default: undefined }) endSelector!: Date | undefined;
  //Las siguientes propiedades maxToday y maxRangeMonth necesitan de startSelector para funcionar, no son compatibles con endSelector
  @Prop({ required: false, default: false }) maxToday!: boolean; // Marcar como dia maximo hoy
  @Prop({ required: false, default: undefined }) maxRangeMonth!: number | undefined; //Marcar como dia maximo fecha inicio mas x meses
  @Prop({ required: false, default: "bottom-start" }) placement!: Placement;

  verNVeces = 0;
  pussOption = false;
  mesMostrado = 1;
  anoMostrado = 2021;
  pulsadoApertura = false;
  traductor: ITranslationsDatepicker = translations[0].translations;
  created(): void {
    const languaje = translations.find((tran) => tran.language === this.language)?.translations;
    if (languaje) {
      this.traductor = languaje;
    }
  }
  mounted(): void {
    this.initDate();
  }
  initDate(): void {
    if (this.value) {
      const dateValue = new Date(this.value);
      this.mesMostrado = dateValue.getMonth();
      this.anoMostrado = dateValue.getFullYear();
    } else {
      const hoy = new Date();
      this.mesMostrado = hoy.getMonth();
      this.anoMostrado = hoy.getFullYear();
    }
  }

  get dameiClass(): string {
    if (this.state) {
      return "p_icon_resuelto";
    } else {
      return "p_icon_error";
    }
  }
  get dateInput(): string {
    if (this.value) {
      return format(new Date(this.value), "dd/MM/yyyy");
    } else {
      return "";
    }
  }
  get ano(): number {
    return this.anoMostrado;
  }
  get mes(): string {
    if (this.traductor) {
      return this.traductor["mes_" + (this.mesMostrado + 1)];
    } else {
      return "";
    }
  }
  get diasMesAnterior(): number[] {
    const fecha01 = new Date(this.anoMostrado, this.mesMostrado, 1);

    let diaSemana = fecha01.getDay();
    const diasAnteriores = [];
    if (diaSemana === 0) {
      diaSemana = 7;
    }
    for (let i = 1; i < diaSemana; i++) {
      diasAnteriores.push(i);
    }
    return diasAnteriores;
  }
  get diasMesPosterior(): number[] {
    const numDiasMes = new Date(this.anoMostrado, this.mesMostrado + 1, 0).getDate();

    const fechaUltimoDia = new Date(this.anoMostrado, this.mesMostrado, numDiasMes);
    const diaSemana = fechaUltimoDia.getDay();
    const diasPosteriores = [];
    for (let i = diaSemana; i < 7; i++) {
      diasPosteriores.push(i);
    }
    return diasPosteriores;
  }
  get diasMes(): number[] {
    const numDiasMes = new Date(this.anoMostrado, this.mesMostrado + 1, 0).getDate();
    const dias = [];
    for (let i = 1; i <= numDiasMes; i++) {
      dias.push(i);
    }
    return dias;
  }
  get diasSemana(): string[] {
    return [
      this.traductor?.L,
      this.traductor?.M,
      this.traductor?.X,
      this.traductor?.J,
      this.traductor?.V,
      this.traductor?.S,
      this.traductor?.D,
    ];
  }

  get fechaFin(): Date | undefined {
    if (this.endSelector) {
      return this.endSelector;
    } else if (this.startSelector && (this.maxRangeMonth || this.maxToday)) {
      let fechaMax = new Date(this.startSelector);
      if (this.maxRangeMonth) {
        fechaMax.setMonth(fechaMax.getMonth() + this.maxRangeMonth);
        let today = new Date();
        if (this.maxToday && fechaMax > today) {
          fechaMax = today;
        }
      } else {
        fechaMax = new Date();
      }
      let fechaFin = new Date(this.value);
      if (fechaMax < fechaFin) {
        this.$emit("input", fechaMax);
      }
      return fechaMax;
    }

    return undefined;
  }

  seleccionarDia(DiaSeleccionado: number): void {
    const fechaHora = new Date(this.anoMostrado, this.mesMostrado, DiaSeleccionado, 0, 0, 0);
    const fechaHoraComparadora = new Date(
      this.anoMostrado,
      this.mesMostrado,
      DiaSeleccionado,
      0,
      0,
      0
    );
    let estaEnRango = true;
    if (
      this.startSelector &&
      fechaHoraComparadora.getTime() <
        (typeof this.startSelector === "string"
          ? new Date(this.startSelector)
          : this.startSelector
        ).getTime()
    ) {
      estaEnRango = false;
    }
    if (
      this.fechaFin &&
      fechaHoraComparadora.getTime() >
        (typeof this.fechaFin === "string" ? new Date(this.fechaFin) : this.fechaFin).getTime()
    ) {
      estaEnRango = false;
    }

    if (estaEnRango) {
      this.pussOption = false;
      this.$emit("input", fechaHora);
    }
  }
  classDiaSeleccionado(dia: number): string {
    const fechaHoraComparadora = new Date(this.anoMostrado, this.mesMostrado, dia, 0, 0, 0);
    let estaEnRango = true;
    let classDia = "divDiaMesDisabled";
    if (
      this.startSelector &&
      fechaHoraComparadora.getTime() <
        (typeof this.startSelector === "string"
          ? new Date(this.startSelector)
          : this.startSelector
        ).getTime()
    ) {
      estaEnRango = false;
    }
    if (
      this.fechaFin &&
      fechaHoraComparadora.getTime() >
        (typeof this.fechaFin === "string" ? new Date(this.fechaFin) : this.fechaFin).getTime()
    ) {
      estaEnRango = false;
    }

    if (estaEnRango) {
      classDia = "divDiaMesEnable";
    }

    if (this.value) {
      const dateValue = new Date(this.value);
      const diaSelec = new Date(dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate());
      const diaCurrent = new Date(this.anoMostrado, this.mesMostrado, dia);
      if (diaSelec.getTime() === diaCurrent.getTime()) {
        return "diaSeleccionado";
      } else {
        return classDia;
      }
    } else {
      return classDia;
    }
  }

  anoAtras(): void {
    this.anoMostrado = this.anoMostrado - 1;
  }
  mesAtras(): void {
    if (this.mesMostrado === 0) {
      //cambiamos de año
      this.mesMostrado = 11;
      this.anoMostrado = this.anoMostrado - 1;
    } else {
      this.mesMostrado = this.mesMostrado - 1;
    }
  }
  hoy(): void {
    const hoy = new Date();
    this.mesMostrado = hoy.getMonth();
    this.anoMostrado = hoy.getFullYear();

    this.$emit("input", new Date(this.anoMostrado, this.mesMostrado, hoy.getDate()));
  }
  mesAdelante(): void {
    if (this.mesMostrado === 11) {
      //pasamos de año
      this.mesMostrado = 0;
      this.anoMostrado = this.anoMostrado + 1;
    } else {
      this.mesMostrado = this.mesMostrado + 1;
    }
  }
  anoAdelante(): void {
    this.anoMostrado = this.anoMostrado + 1;
  }
}
export default PFormDatepicker;
