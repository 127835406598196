
import { Component, Prop, Vue } from "vue-property-decorator";
import ClientesTable from "./ClientesTable.vue";
import ClienteForm from "./ClienteForm.vue";
import ClienteFilterForm from "./ClienteFilterForm.vue";
import VueMethods from "@/vue-methods";
import { ICliente, IPais } from "@/entities";
import {
  EDirection,
  IClienteFilter,
  IClienteParams,
  IClienteResponse,
  IClienteTableContent,
  IColumnaResponse,
  iniClienteParams,
} from "@/services";
import { Action, Getter } from "vuex-class";
import { Dictionary } from "vue-router/types/router";

@Component({
  components: { ClienteForm, ClientesTable, ClienteFilterForm },
  mixins: [VueMethods],
})
export default class AdminClientesComponent extends Vue {
  @Prop({ required: true }) columnsConfigCliente!: IColumnaResponse;
  @Getter("getPaises") getPaises!: IPais[];
  @Action("fetchCliente") fetchClientes!: (
    params?: IClienteFilter
  ) => Promise<IClienteResponse | undefined>;
  @Action("saveCliente") saveCliente!: (
    soClienteUpdateDTO: IClienteParams
  ) => Promise<ICliente | undefined>;

  actions: any[];
  clienteMod: IClienteParams = iniClienteParams;
  verModal = false;
  titleModal = "";
  actionClienteForm = "";
  textActionClienteForm = "";
  mostrarFiltros = false;
  queryParams = this.$route.query;
  filtersCliente: IClienteFilter = {
    paises: [],
    cliente: this.queryParams.cliente ? this.queryParams.cliente.toString() : "",
    fechaBaja: this.getStartDate(this.queryParams.fechaBaja?.toString()) ?? "",
    fechaCreacion: this.getStartDate(this.queryParams.fechaCreacion?.toString()) ?? "",
    fechaModificacion: this.getStartDate(this.queryParams.fechaModificacion?.toString()) ?? "",
    activeDesc: "",
    active: this.queryParams.active ? Boolean(this.queryParams.active) : false,
  };
  clientesAll: IClienteTableContent[] = [];

  constructor() {
    super();
    this.actions = [{ action: "newCliente", label: this.$t("label.cliente") }];
  }

  abrirModal(accion: string): void {
    if (accion === "newCliente") {
      this.actionClienteForm = "crear";
      this.textActionClienteForm = this.$t("action.crear");
      this.titleModal = this.$t("label.crear.cliente");
      this.clienteMod = iniClienteParams;
      this.verModal = true;
    }
  }

  abrirModalModificarCliente(row: ICliente): void {
    this.actionClienteForm = "modificar";
    this.textActionClienteForm = this.$t("label.modificar");
    //rellenamos todas las delegaciones
    const delegaciones: number[] = [];
    row.clientexdels.forEach((clienex) => {
      if (clienex.active) {
        delegaciones.push(clienex.delegacion.id);
      }
    });
    this.clienteMod = {
      active: row.active,
      codCliente: row.codCliente,
      desCliente: row.desCliente,
      id: row.id,
      fakeCerts: row.fakeCerts,
      delegaciones: delegaciones,
      pais: row.pais.id,
    };
    this.titleModal = this.$t("label.modificarCliente");
    this.verModal = true;
  }

  crearCliente(data: IClienteParams): void {
    const params: IClienteParams = {
      active: data.active,
      codCliente: data.codCliente,
      desCliente: data.desCliente,
      fakeCerts: data.fakeCerts,
      pais: data.pais,
      delegaciones: data.delegaciones,
    };

    this.saveCliente(params).then(() => {
      this.verModal = false;
      this.getClientes();
    });
  }

  modificarCliente(data: IClienteParams): void {
    const params: IClienteParams = {
      id: data.id,
      active: data.active,
      codCliente: data.codCliente,
      desCliente: data.desCliente,
      fakeCerts: data.fakeCerts,
      pais: data.pais,
      delegaciones: data.delegaciones,
    };
    this.saveCliente(params).then(() => {
      this.verModal = false;
      this.getClientes();
    });
  }

  action(obj: { action: string; data: IClienteParams; dataFilters?: any }): void {
    if (obj.action === "crear") {
      this.crearCliente(obj.data);
    } else if (obj.action === "modificar") {
      this.modificarCliente(obj.data);
    } else if (obj.action === "filtrar" || obj.action === "clean") {
      this.getClientes();
      this.mostrarFiltros = false;
    } else if (obj.action === "changePagination" || obj.action === "sortingChange") {
      const paramsFilter = this.createParamFilter({ id: obj.dataFilters.idPais } as IPais);
      paramsFilter.page = obj.dataFilters.pagination.page! - 1;
      paramsFilter.size = obj.dataFilters.pagination.size;
      paramsFilter.orderBy = obj.dataFilters.pagination.orderBy;
      paramsFilter.direction = obj.dataFilters.pagination.direction;

      this.getClientesByFiltersAndCodPais(paramsFilter, obj.dataFilters.codPais);
    }
  }
  created(): void {
    this.getClientes();
  }

  get dameTextoBotonFiltros(): string {
    let numFiltros = 0;
    Object.keys(this.filtersCliente).forEach((key: string) => {
      if (this.filtersCliente[key] != "") {
        numFiltros = numFiltros + 1;
      }
    });

    return numFiltros ? `(${numFiltros}) ${this.$t("label.filtros")}` : this.$t("label.filtros");
  }

  get clientesAllComp(): IClienteTableContent[] {
    return this.clientesAll.sort((a, b) => {
      return a.codPais.toLowerCase() < b.codPais.toLowerCase() ? -1 : 1;
    });
  }

  async getClientes(): Promise<void> {
    this.clientesAll = [];
    this.getPaises
      .filter(
        (pais) =>
          !this.filtersCliente.paises ||
          !this.filtersCliente.paises.length ||
          this.filtersCliente.paises.includes(pais.id)
      )
      .map((pais) => this.createParamFilter(pais))
      .map((params) => {
        this.$nextTick(() => {
          this.$router
            .replace({ path: this.$route.path, query: { ...params } as Dictionary<any> })
            .catch(() => {
              // Do nothing
            });
        });
        return this.fetchClientes(params).then((cliente) => {
          if (cliente && cliente.content.length > 0) {
            this.clientesAll.push({
              items: cliente.content,
              orderBy: params.orderBy,
              sortDesc: params.direction === EDirection.DESC,
              codPais: cliente.content[0].pais.codPais,
              idPais: cliente.content[0].pais.id,
              pagination: {
                totalElements: cliente.content.length,
                size: params.size,
                page: params.page! + 1,
                direction: params.direction,
              },
              collapsed: true,
              totalElements: cliente.totalElements,
            });
          }
        });
      });
  }

  private createParamFilter(pais: IPais): IClienteFilter {
    return {
      orderBy: "id",
      page: 0,
      size: 10,
      pais: pais.id,
      cliente: this.filtersCliente.cliente ? this.filtersCliente.cliente.trim() : undefined,
      fechaBaja: this.filtersCliente.fechaBaja
        ? this.getStartDate(this.filtersCliente.fechaBaja)
        : undefined,
      fechaCreacion: this.filtersCliente.fechaCreacion
        ? this.getStartDate(this.filtersCliente.fechaCreacion)
        : undefined,
      fechaModificacion: this.filtersCliente.fechaModificacion
        ? this.getStartDate(this.filtersCliente.fechaModificacion)
        : undefined,
      active: this.filtersCliente.activeDesc ? this.filtersCliente.activeDesc === "SI" : undefined,
    };
  }

  async getClientesByFiltersAndCodPais(filters: IClienteFilter, codPais: string): Promise<void> {
    this.fetchClientes(filters).then((clientesPais) => {
      const clientesAllFiltered = this.clientesAll.filter(
        (clientes) => codPais != clientes.codPais
      );
      this.clientesAll = [...clientesAllFiltered];
      if (clientesPais && clientesPais.content.length > 0) {
        this.clientesAll.push({
          items: clientesPais.content,
          orderBy: filters.orderBy,
          sortDesc: filters.direction === EDirection.DESC,
          codPais: codPais,
          idPais: filters.pais!,
          pagination: {
            totalElements: clientesPais.content.length,
            size: filters.size,
            page: filters.page! + 1,
            direction: filters.direction,
          },
          collapsed: true,
          totalElements: clientesPais.totalElements,
        });
      }
    });
  }
}
