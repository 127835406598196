
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { Locale } from "@/store";
import VueMethods from "@/vue-methods";
import { IVideoFilter } from "@/services";
import { IParametro } from "@/entities";

@Component({
  mixins: [VueMethods],
})
export class VideosFilterForm extends Vue {
  @Prop({ required: true }) value!: IVideoFilter;
  @Prop({ required: true }) optionEstados!: IParametro[];
  @Prop({ required: true }) optionTiposNovedad!: IParametro[];
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.$set(
      this.valueLocal,
      "fechaNovedad",
      this.valueLocal.fechaNovedad
        ? new Date(this.valueLocal.fechaNovedad)
        : this.getStartDate(new Date().toISOString())
    );
    this.$set(
      this.valueLocal,
      "fechaSubida",
      this.valueLocal.fechaSubida ? new Date(this.valueLocal.fechaSubida) : undefined
    );
  }
  validado = false;
  valueLocal: IVideoFilter = {
    ciudad: undefined,
    sucursal: undefined,
    puntoServicio: undefined,
    fechaNovedad: this.getStartDate(new Date().toISOString()),
    fechaSubida: undefined,
    nombreRevisores: undefined,
    cedulasCiudadania: undefined,
    observaciones: undefined,
    nombreArchivo: undefined,
    estado: [],
    tipoNovedad: [],
  };
  initialValueLocal: IVideoFilter = {
    ciudad: undefined,
    sucursal: undefined,
    puntoServicio: undefined,
    fechaNovedad: this.getStartDate(new Date().toISOString()),
    fechaSubida: undefined,
    nombreRevisores: undefined,
    cedulasCiudadania: undefined,
    observaciones: undefined,
    nombreArchivo: undefined,
    estado: [],
    tipoNovedad: [],
  };
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;
  @Getter("getLocale") locale!: Locale;

  limpiar(): void {
    this.valueLocal.ciudad = undefined;
    this.valueLocal.sucursal = undefined;
    this.valueLocal.puntoServicio = undefined;
    this.valueLocal.nombreRevisores = undefined;
    this.valueLocal.cedulasCiudadania = undefined;
    this.valueLocal.observaciones = undefined;
    this.valueLocal.nombreArchivo = undefined;
    this.valueLocal.estado = [];
    this.valueLocal.tipoNovedad = [];
    this.$set(this.valueLocal, "fechaNovedad", this.getStartDate(new Date().toISOString()));
    this.$set(this.valueLocal, "fechaSubida", undefined);
    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}
export default VideosFilterForm;
