
import { Component, Vue } from "vue-property-decorator";
import NotificacionesDetalle from "./NotificacionesDetalle.vue";
import NotificacionesReactivar from "./NotificacionesReactivar.vue";

@Component({
  components: {
    NotificacionesDetalle,
    NotificacionesReactivar,
  },
})
export class NotificacionesRecientes extends Vue {
  verNotificacionesDetalle = false;
  verNotificacionesReactivar = false;
  titleModal = "";
  verModal = false;
  notificacionesSaldos = [
    {
      asunto: "Saldo cargado",
      saldoTotalCargado: "EUR 323.382,48",
      sucursal: "Mar de Plata",
    },
    {
      asunto: "Saldo cargado",
      saldoTotalCargado: "EUR 323.382,48",
      sucursal: "Mar de Plata",
    },
    {
      asunto: "Saldo cargado",
      saldoTotalCargado: "EUR 323.382,48",
      sucursal: "Mar de Plata",
    },
  ];
  fieldsNotificacionesSaldos = {
    saldoTotalCargado: { title: this.$t("label.saldo.total.cargado"), width: "150px" },
    sucursal: { title: this.$t("label.sucursal"), width: "150px", divider: true },
  };
  notificacionesTransaferencias = [
    {
      asunto: "Demanda rechazada",
      remitente: "Banco XXXX",
      denominacion: "B P100 BUEN USO",
      demandado: "1.000.000.00,00",
    },
    {
      asunto: "Oferta expirada",
      denominacion: "B P100 BUEN USO",
      ofertado: "1.000.000.00,00",
    },
    {
      asunto: "Demanda rechazada",
      remitente: "Banco XXXX",
      denominacion: "B P100 BUEN USO",
      ofertado: "1.000.000.00,00",
    },
  ];
  fieldsNotificacionesTransaferencias = {
    remitente: { title: this.$t("label.recientemente"), width: "30px", emptyShow: false },
    denominacion: {
      title: this.$t("notificaciones.denominacion"),
      width: "30px",
      divider: true,
      emptyShow: false,
    },
    demandado: {
      title: this.$t("notificaciones.demandado"),
      width: "30px",
      divider: true,
      emptyShow: false,
    },
    ofertado: {
      title: this.$t("notificaciones.ofertado"),
      width: "30px",
      divider: true,
      emptyShow: false,
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
  showDetails(notificacion: any): void {
    this.verModal = true;
    this.titleModal = this.$t("notificaciones.detalle");
    this.verNotificacionesDetalle = true;
    this.verNotificacionesReactivar = false;
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/explicit-module-boundary-types
  reactivar(notificacion: any): void {
    this.verModal = true;
    this.titleModal = this.$t("label.reactivar");
    this.verNotificacionesDetalle = false;
    this.verNotificacionesReactivar = true;
  }
}
export default NotificacionesRecientes;
