import { render, staticRenderFns } from "./DemandarForm.vue?vue&type=template&id=1805b3be&scoped=true"
import script from "./DemandarForm.vue?vue&type=script&lang=ts"
export * from "./DemandarForm.vue?vue&type=script&lang=ts"
import style0 from "./DemandarForm.vue?vue&type=style&index=0&id=1805b3be&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1805b3be",
  null
  
)

export default component.exports