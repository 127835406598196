
import { Component, Prop, Vue } from "vue-property-decorator";
import { IDetalleTransferencia, IOfertaDemanda, ITransferenciaRelacionar } from "@/entities";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import VueFilters from "@/vue-filters";
import TransferenciaDetalle from "@/pages/transferencias/common/TransferenciaDetalle.vue";
import OfertaDemandaDetalle from "@/pages/transferencias/common/OfertaDemandaDetalle.vue";
import { SweetAlertResult } from "sweetalert2";

@Component({
  name: "relacionar-con-demanda",
  mixins: [VueFilters],
  components: {
    OfertaDemandaDetalle,
    TransferenciaDetalle,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class RelacionarConDemanda extends Vue {
  @Prop({ required: true }) oferta!: IOfertaDemanda;
  @Prop({ required: true }) demanda!: IOfertaDemanda;
  isShowBankField = false;

  $refs!: {
    formDemandaCompatible: InstanceType<typeof ValidationObserver>;
  };

  mounted(): void {
    this.isShowBankField = this.oferta.clientexdel.cliente.pais.hasInfoBancaria || false;

    if (this.demanda.checkParcial) {
      this.oferta.detallesOferta!.forEach((value) => {
        this.$set(value.soCalidadDenominacion, "importe", 0);
      });
    }
  }

  get continuarCondition(): boolean {
    return !(!this.demanda.checkParcial || (this.saldoOfertar > 0 && this.demanda.checkParcial));
  }

  get fields_PTableSimple(): { key: string; label: string }[] {
    return [
      { key: "denominacion", label: this.$t("label.denominacion") },
      { key: "disponible", label: this.$t("label.disponible") },
      { key: "ofertado", label: this.$t("label.ofertado") },
    ];
  }

  get detallesRelacionar(): IDetalleTransferencia[] {
    let detallesRelacionar: IDetalleTransferencia[] = [];
    this.oferta.detallesOferta!.forEach((detalleOf) => {
      let detalleDemanda = this.demanda.detallesDemanda!.find(
        (detalleDe) => detalleDe.soCalidadDenominacion.id === detalleOf.soCalidadDenominacion.id
      );
      if (detalleDemanda) {
        let detalleRelacionar: IDetalleTransferencia = {
          id: 0,
          cantidadDemandada: 0,
          cantidadTransferida: 0,
          cantidadOrigen: detalleDemanda.cantidadDisponible,
          cantidadSolicitud: 0,
          soCalidadDenominacion: detalleOf.soCalidadDenominacion,
          soVDetalleOferta: detalleOf,
          soVDetalleDemanda: detalleDemanda,
        };
        detallesRelacionar.push(detalleRelacionar);
      }
    });
    return detallesRelacionar;
  }

  get saldoRestante(): number {
    return this.oferta.cantidadDisponible - this.saldoOfertar;
  }

  get saldoOfertar(): number {
    let sumaImportes = 0;
    if (this.demanda.checkParcial) {
      this.oferta.detallesOferta!.forEach((value) => {
        if (value.soCalidadDenominacion.importe) {
          sumaImportes = Number(sumaImportes) + Number(value.soCalidadDenominacion.importe);
        }
      });
      return sumaImportes;
    } else {
      return this.demanda.cantidadDisponible;
    }
  }

  cancelar(): void {
    this.$emit("cancel");
  }

  async confirmOferta(): Promise<void> {
    const res = await this.confirmTransferencia();
    if (res.isConfirmed) {
      await this.ofertar();
    }
  }

  async confirmTransferencia(): Promise<SweetAlertResult> {
    return this.$swal({
      title: this.$t("label.confirmar"),
      text: this.$t("label.transferencias.disclaimer"),
      icon: "question",
      showCancelButton: true,
      confirmButtonText: this.$t("label.yes"),
      cancelButtonText: this.$t("label.no"),
    });
  }

  async ofertar(): Promise<void> {
    let transferencia: ITransferenciaRelacionar = {
      idOferta: this.oferta.id,
      idDemanda: this.demanda.id,
      detalles: [],
    };
    if (this.demanda.checkParcial) {
      this.detallesRelacionar.forEach((value) => {
        if (value.soCalidadDenominacion.importe > 0) {
          transferencia.detalles.push({
            idCalidadDenominacion: value.soCalidadDenominacion.id,
            cantidadOfertada: value.soCalidadDenominacion.importe,
          });
        }
      });
    } else {
      this.detallesRelacionar.forEach((value) => {
        transferencia.detalles.push({
          idCalidadDenominacion: value.soCalidadDenominacion.id,
          cantidadOfertada: value.cantidadOrigen,
        });
      });
    }
    this.$emit("action", { action: "relacionarConDemanda", data: transferencia });
  }
}
