
import { Component, Prop, Vue } from "vue-property-decorator";
import { EDirection, IColumnaResponse, IPaged } from "@/services";
import { IDivisa, IField, IOfertaDemanda, ITransferencia } from "@/entities";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import VueFilters from "@/vue-filters";
import DetalleTransferencia from "@/pages/transferencias/propias/DetalleTransferencia.vue";
import TransferenciasCompatibles from "@/pages/transferencias/propias/TransferenciasCompatibles.vue";
import Pagination from "@/components/common/Pagination.vue";

@Component({
  components: {
    Pagination,
    TransferenciasCompatibles,
    DetalleTransferencia,
    SelectColumns,
  },
  mixins: [VueFilters],
})
export class TransferenciasPropiasTable extends Vue {
  @Prop({ required: false, default: 0 }) totalElements!: number;
  @Prop({ required: true }) value!: IPaged;
  @Prop({ required: true }) transferencias!: IOfertaDemanda[];
  @Prop({ required: true }) divisa!: IDivisa;
  @Prop({ required: true }) tipo!: "OFERTA" | "DEMANDA";
  @Prop({ required: true }) cantidadDisponible!: number;
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;

  public sortDesc = false;
  public pagination: IPaged = {
    direction: EDirection.ASC,
    orderBy: "fecCrear",
    totalElements: 0,
    page: 1,
    size: 10,
  };

  onSortingChange(): void {
    this.$nextTick(async () => {
      this.pagination.direction = this.sortDesc ? EDirection.DESC : EDirection.ASC;
      this.$emit("input", this.pagination);
      this.$emit("change", {
        action: "changeTable",
        data: { tipo: this.tipo, divisa: this.divisa },
      });
    });
  }
  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }
  hasNumTasa(): boolean {
    const hasNumTasaList: IOfertaDemanda[] = [];
    this.transferencias.forEach((transferencia) => {
      if (transferencia.numTasa != null) {
        hasNumTasaList.push(transferencia);
      }
    });
    return hasNumTasaList.length > 0;
  }
  cancelarOferta(id: string): void {
    this.$emit("cancelOferta", { action: "cancelOferta", data: id });
  }
  cancelarDemanda(id: string): void {
    this.$emit("cancelDemanda", { action: "cancelDemanda", data: id });
  }
  rechazarDemanda(id: number): void {
    this.$emit("rechazarDemanda", { action: "rechazarDemanda", data: id });
  }
  cancelarDemandaPrivada(id: number): void {
    this.$emit("cancelarDemandaPrivada", { action: "cancelarDemandaPrivada", data: id });
  }
  aceptarDemanda(transferencia: ITransferencia): void {
    this.$emit("aceptarDemanda", { action: "aceptarDemanda", data: transferencia });
  }
  relacionarDemanda(data: { demandaCompatible: IOfertaDemanda; oferta: IOfertaDemanda }): void {
    this.$emit("relacionarDemanda", { action: "relacionarDemanda", data: data });
  }
  relacionarOferta(data: { ofertaCompatible: IOfertaDemanda; demanda: IOfertaDemanda }): void {
    this.$emit("relacionarOferta", { action: "relacionarOferta", data: data });
  }

  onChangeCurrentPage(currentPage: number): void {
    this.pagination.page = currentPage;
    this.$emit("input", this.pagination);
    this.$emit("change", { action: "changeTable", data: { tipo: this.tipo, divisa: this.divisa } });
  }

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    const columns = [
      {
        key: "actions-left",
        label: this.$t("label.acciones"),
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first actions",
        visible: true,
        class: "table-cell",
        aux: true,
      },
      {
        key: "estado",
        label: this.$t("label.estado"),
        sortable: true,
        thClass: "table-header",
        tdClass: (value: string): string => "table-cell-middle " + value.toLowerCase(),
        visible: this.isVisible(visibleKeys, "estado"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "clientexdel.cliente.desCliente",
        label: this.$t("label.cliente"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "clientexdel.cliente.desCliente"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "clientexdel.delegacion.desDelegacion",
        label: this.$t("label.delegacion"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "clientexdel.delegacion.desDelegacion"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "cantidadOfertada",
        label: this.$t("label.cantidadInicial"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "cantidadOfertada"),
        class: "table-cell",
        formatter: (value: number): string => this.filterNumber(value, 2),
        aux: false,
      },
      {
        key: "cantidadDemandada",
        label: this.$t("label.cantidadInicial"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "cantidadDemandada"),
        class: "table-cell",
        formatter: (value: number): string => this.filterNumber(value, 2),
        aux: false,
      },
      {
        key: "cantidadDisponible",
        label: this.$t("label.cantidadDisponible"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "cantidadDisponible"),
        class: "table-cell",
        formatter: (value: number): string => this.filterNumber(value, 2),
        aux: false,
      },
      {
        key: "fecCrear",
        label: this.$t("label.creacion"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "fecCrear"),
        class: "table-cell",
        formatter: (value: string): string => this.filterDateTime(value),
        aux: false,
      },
      {
        key: "fecModificacion",
        label: this.$t("label.modificacion"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "fecModificacion"),
        class: "table-cell",
        formatter: (value: string): string => this.filterDateTime(value),
        aux: false,
      },
      {
        key: "fecExpirar",
        label: this.$t("label.expiracion"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "fecExpirar"),
        class: "table-cell",
        formatter: (value: string): string => this.filterDateTime(value),
        aux: false,
      },
      {
        key: "coste",
        label: this.$t("label.coste"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "coste"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "numTasa",
        label: this.$t("label.tasa"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "numTasa") && this.hasNumTasa(),
        class: "table-cell",
        formatter: (value: number): string => value.toString() + " %",
        aux: false,
      },
      {
        key: "checkParcial",
        label: this.$t("label.parcial"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: this.isVisible(visibleKeys, "checkParcial"),
        class: "table-cell",
        aux: false,
      },
      {
        key: "add-columns",
        label: "",
        sortable: false,
        thClass: "table-header table-header-add-columnas",
        tdClass: "table-cell table-add-columns",
        visible: true,
        aux: true,
      },
    ];
    if (this.tipo === "OFERTA") {
      return columns.filter(function (value) {
        return value.key != "checkParcial" && value.key != "cantidadDemandada";
      });
    } else if (this.tipo === "DEMANDA") {
      return columns.filter(function (value) {
        return value.key != "cantidadOfertada";
      });
    }
    return columns;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible)
        .map((item) => item.key)
        .join(",") || "";
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }
}
export default TransferenciasPropiasTable;
