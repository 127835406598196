
import VueFilters from "@/vue-filters";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Action } from "vuex-class";
import { IField, ITransferencia } from "@/entities";
import { IOfertaDemanda } from "@/entities/centros-efectivo-backend/ofertaDemanda.types";

@Component({
  name: "detalle-transferencia",
  mixins: [VueFilters],
})
export default class DetalleTransferencia extends Vue {
  @Prop({ required: true }) ofertaDemanda!: IOfertaDemanda;
  @Prop({ required: true }) tipo!: "OFERTA" | "DEMANDA";
  @Action("fetchByOferta") fetchByOferta!: (id: number) => Promise<ITransferencia[] | undefined>;
  @Action("fetchByDemanda") fetchByDemanda!: (id: number) => Promise<ITransferencia[] | undefined>;

  @Action("setLoading") setLoading!: (loading: boolean) => void;

  detalles: ITransferencia[] = [];

  get title(): string {
    return this.tipo === "OFERTA"
      ? this.$t("label.entidadesOferta")
      : this.$t("label.entidadesDemanda");
  }

  get fields(): IField[] {
    const columns = [
      {
        key: "actions-left",
        label: this.$t("label.acciones"),
        sortable: false,
        thClass: "table-header ml-2",
        tdClass: "table-cell-first actions",
        visible: true,
        class: "table-cell",
        aux: true,
      },
      {
        key: "soVOferta.clientexdel.cliente.desCliente",
        label: this.$t("label.cliente"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        aux: false,
      },
      {
        key: "soVDemanda.clientexdel.cliente.desCliente",
        label: this.$t("label.cliente"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        aux: false,
      },
      {
        key: "cantidadDemandada",
        label: this.$t("label.cantidad"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        formatter: (value: number, _key: string, item: ITransferencia): string => {
          if (item.estado === "ACEPTADA") return this.filterNumber(item.cantidadTransferida, 2);
          else return this.filterNumber(value, 2);
        },
        aux: false,
      },
      {
        key: "soVOferta.coste.desParametro",
        label: this.$t("label.coste"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        aux: false,
      },
      {
        key: "estado",
        label: this.$t("label.estado"),
        sortable: true,
        thClass: "table-header",
        tdClass: (value: string): string => "table-cell-middle " + value.toLowerCase(),
        visible: true,
        class: "table-cell",
        aux: false,
      },
      {
        key: "fecCrear",
        label: this.$t("label.creacion"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        formatter: (value: string): string => this.filterDateTime(value),
        aux: false,
      },
      {
        key: "soVOferta.fecExpirar",
        label: this.$t("label.expiracion"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        formatter: (value: string): string => this.filterDateTime(value),
        aux: false,
      },
      {
        key: "soVDemanda.fecExpirar",
        label: this.$t("label.expiracion"),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        class: "table-cell",
        formatter: (value: string): string => this.filterDateTime(value),
        aux: false,
      },
    ];

    if (this.tipo === "DEMANDA") {
      return columns.filter(function (value) {
        return (
          value.key != "checkParcial" &&
          value.key != "soVDemanda.clientexdel.cliente.desCliente" &&
          value.key != "soVDemanda.fecExpirar"
        );
      });
    } else if (this.tipo === "OFERTA") {
      return columns.filter(function (value) {
        return (
          value.key != "soVOferta.clientexdel.cliente.desCliente" &&
          value.key != "soVOferta.fecExpirar"
        );
      });
    }
    return columns;
  }
  constructor() {
    super();
  }
  mounted(): void {
    this.fetch();
  }

  rechazarDemanda(id: number): void {
    this.$emit("rechazarDemanda", id);
  }
  aceptarDemanda(transferencia: ITransferencia): void {
    this.$emit("aceptarDemanda", transferencia);
  }
  cancelarDemanda(id: number): void {
    this.$emit("cancelarDemandaPrivada", id);
  }

  async fetch(): Promise<void> {
    if (this.tipo === "OFERTA") {
      const response = await this.fetchByOferta(this.ofertaDemanda.id);
      if (response) {
        this.detalles = response;
      }
    } else {
      const response = await this.fetchByDemanda(this.ofertaDemanda.id);
      if (response) {
        this.detalles = response;
      }
    }
  }
}
