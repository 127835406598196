import { ITransferenciasServiceMock } from "@/services/saldosonline/transferencias/transferencias.types";

export const mockTransferenciasService = (): ITransferenciasServiceMock => ({
  fetchPublicas: jest.fn(),
  fetchPropias: jest.fn(),
  fetchTiposCoste: jest.fn(),
  fetchTiposExportacion: jest.fn(),
  getExcelTransferencias: jest.fn(),
  getPdfTransferencias: jest.fn(),
  getTxtTransferencias: jest.fn(),
  fetchByOferta: jest.fn(),
  fetchByDemanda: jest.fn(),
  rejectTransferencia: jest.fn(),
  cancelDemandaPrivada: jest.fn(),
  aceptarDemandaPrivada: jest.fn(),
  relacionarConDemanda: jest.fn(),
  _downloadPlantilla: jest.fn(),
  importTransferencias: jest.fn(),
});
