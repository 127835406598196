
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import { mapGetters } from "vuex";
import { IPermiso, IUsuario } from "@/entities";
import DisclaimerModal from "@/components/common/widgets/DisclaimerModal.vue";

@Component({
  name: "sidebar",
  components: { DisclaimerModal },
  computed: {
    ...mapGetters(["getIsSignedIn"]),
  },
})
export default class Sidebar extends Vue {
  @Getter("getLoggedUser") loggedUser: IUsuario | undefined;
  @Action("logout") logout!: () => Promise<void>;
  @Getter("getUrl") getUrl!: string;

  showSubMenu = true;
  titleSubMenu = "";
  menu = [
    { name: "dashboard", parent: "dashboard" },
    { name: "todos", parent: "saldos" },
    { name: "entradas", parent: "saldos" },
    { name: "salidas", parent: "saldos" },
    { name: "todosBCR", parent: "saldosBCR" },
    { name: "entradasBCR", parent: "saldosBCR" },
    { name: "salidasBCR", parent: "saldosBCR" },
    { name: "historico", parent: "documentacion" },
    { name: "delegacion", parent: "documentacion" },
    { name: "importacion", parent: "documentacion" },
    { name: "documentos", parent: "documentacion" },
    { name: "administracion", parent: "administracion" },
    { name: "clientes", parent: "administracion" },
    { name: "usuarios", parent: "administracion" },
    { name: "columnas", parent: "administracion" },
    { name: "cargas", parent: "administracion" },
    { name: "transferencias", parent: "transferencias" },
    { name: "propias", parent: "transferencias" },
    { name: "publicas", parent: "transferencias" },
    { name: "videos", parent: "videos" },
    { name: "reportes", parent: "reportes" },
    { name: "notificaciones", parent: "notificaciones" },
    { name: "recientes", parent: "notificaciones" },
    { name: "historicos", parent: "notificaciones" },
  ];

  public tooltipOptions = {
    customClass: "tooltip-dark",
    delay: { show: 1000, hide: 0 },
  };

  get fullName(): string {
    return `${this.loggedUser?.desNombre} ${this.loggedUser?.desApellidos}`;
  }
  get letterUser(): string {
    return this.loggedUser!.desNombre[0].toUpperCase();
  }

  get subMenuComp(): { name: string; parent: string }[] {
    const subMenu = this.menu.find((me) => me.name === this.$route.name);
    if (subMenu) {
      this.titleSubMenu = this.$t("menu." + subMenu.parent) + "";
      return this.menu.filter((me) => me.parent === subMenu.parent);
    } else {
      return [];
    }
  }

  classMenuActive(nameRouter: string): string {
    const subMenu = this.menu.find((me) => me.name === this.$route.name);
    if (subMenu && subMenu.parent === nameRouter) {
      return "menuTopItemActive_Sidebar";
    } else {
      return "";
    }
  }

  classSubMenuActive(nameRouter: string): string {
    if (this.$route.name === nameRouter) {
      return "subMenuItemActive_Sidebar";
    } else {
      return "";
    }
  }

  openDisclaimerModal(): void {
    return this.$bvModal.show("disclaimer-modal");
  }

  get isBCR(): boolean {
    const permiso = this.loggedUser?.permisos.find((permiso: IPermiso) => permiso.pais.bcr);
    return this.$ability.can("read", "SaldosPage") && !!permiso;
  }
}
