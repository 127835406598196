
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { IConfColumnaPais, IField, IReporte, ISoConfColumnasUpdateDTO, IUsuario } from "@/entities";
import { EDirection, IColumnaResponse, IPaged } from "@/services";
import VueFilters from "@/vue-filters";
import { Action, Getter } from "vuex-class";
import Pagination from "@/components/common/Pagination.vue";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";
import { ROLE_PAIS } from "@/constans";

@Component({
  components: { SelectColumns, Pagination },
  mixins: [VueFilters],
})
export class ReportesTable extends Vue {
  @Prop({ required: false, default: 0 }) totalElements!: number;
  @Prop({ required: true }) value!: IPaged;
  @Prop({ required: true }) reportes!: IReporte[];
  @Prop({ required: true }) public columnsConfig!: IColumnaResponse;
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;
  @Getter("getLoggedUser") getLoggerUser!: IUsuario;

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [
      {
        key: "actions-left",
        label: "ACCIONES",
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first",
        visible: true,
        class: "table-cell",
        aux: true,
        code: "",
      },
    ];

    if (this.getLoggerUser.nivel === ROLE_PAIS) {
      columnas.push({
        key: "codUsuario",
        label: this.$t("label.idUsuario"),
        sortable: false,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        visible: true,
        aux: true,
        class: "table-cell",
        code: "codUsuario",
      });
    }

    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna),
        sortable: column.sortable,
        thClass: "table-header",
        tdClass: (_value: unknown, key: string): string => `table-cell-middle ${key}`,
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          } else if (column.formatType == "DateTime") {
            return this.filterDateTime(value);
          }
          return value;
        },
      });
    });
    columnas.push({
      key: "actions-right",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas pb-0",
      tdClass: "table-cell",
      visible: true,
      aux: true,
      code: "",
    });
    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  clickAcciones(action: string, object?: any): void {
    if (action === "sort") {
      this.value.direction = object.sortDesc ? EDirection.DESC : EDirection.ASC;
      this.value.orderBy = object.sortBy;
      this.saveColumnsConfig();
      this.$emit("change");
    } else if (action == "changePagination") {
      this.value.page = object;
      this.$emit("change");
    } else if (action == "download") {
      this.$emit("action", { action: "download", data: object });
    } else if (action == "delete") {
      this.$emit("action", { action: "delete", data: object });
    }
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.columnsConfig.codColumna || "",
      codTabla: this.columnsConfig.codTabla,
    };
    if (this.value) {
      params.codTipoOrden = this.value.direction;
      params.numPagElements = this.value.size;
      params.codOrden = this.value.orderBy;
    }
    await this.saveColumna(params);
  }
}
export default ReportesTable;
