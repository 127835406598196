
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export class PFormInputText extends Vue {
  @Prop({ required: true }) value!: string;
  @Watch("value", { immediate: true })
  onChangeValue(): void {
    this.valueLocal = this.value;
    this.showLabelVarientLine();
  }
  @Prop({ required: false, default: "text" }) type!: string;
  @Prop({ required: false, default: undefined }) min!: number;
  @Prop({ required: false, default: undefined }) max!: number;
  @Prop({ required: false, default: undefined }) step!: number;
  @Prop({ required: false, default: "" }) placeholder!: string;
  @Prop({ required: false, default: "" }) label!: string;
  @Prop({ required: false, default: false }) readonly!: boolean;
  @Prop({ required: false, default: "primary" }) variant!: string;
  @Prop({ required: false, default: undefined }) rules!: string;
  @Prop({ required: false, default: "" }) invalid_feedback!: string;
  @Prop({ required: false, default: 4 }) rows!: number;

  valueLocal = "";
  showLabel = false;
  mounted(): void {
    if (this.variant != "line") {
      this.showLabel = true;
    }
  }
  get placeholderComp(): string {
    if (this.variant === "line") {
      return this.label;
    } else {
      return this.placeholder;
    }
  }
  get variantCop(): string {
    return "input_PFormInputText_" + this.variant;
  }
  showLabelVarientLine(): void {
    if (this.variant === "line") {
      if (this.valueLocal != "") {
        this.showLabel = true;
      } else {
        this.showLabel = false;
      }
    }
  }
  input(): void {
    this.showLabelVarientLine();
    this.$emit("input", this.valueLocal);
  }
}
export default PFormInputText;
