
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import VueFilters from "@/vue-filters";
import {
  ICliente,
  IConfColumnaPais,
  IDivisa,
  IEntrada,
  IField,
  ISaldo,
  ISalida,
  ISoConfColumnasUpdateDTO,
} from "@/entities";
import {
  EDirection,
  IColumnaResponse,
  IEntradaData,
  IEntradaParams,
  IMovimientoParams,
  IPaged,
} from "@/services";
import DetalleMovimiento from "../common/DetalleMovimiento.vue";
import Pagination from "@/components/common/Pagination.vue";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";

@Component({
  components: { SelectColumns, DetalleMovimiento, Pagination },
  mixins: [VueFilters],
})
export default class EntradasTable extends Vue {
  @Prop({ required: true }) columnsConfig!: IColumnaResponse;
  @Prop({ required: false, default: null }) saldo!: ISaldo | null;
  @Prop({ required: false, default: null }) divisa!: IDivisa;
  @Prop({ required: false, default: null }) params!: IMovimientoParams;
  @Prop({ required: false, default: false }) isBCR!: boolean;
  @Watch("params", { deep: true })
  onChangeParams(): void {
    this.pagination.page = 1;
    this.getEntradas();
  }
  @Prop({ required: false, default: 0 }) total!: number;
  @Prop({ required: false, default: 0 }) totalElements!: number;
  @Prop({ required: false, default: true }) modal!: boolean;
  @Action("fetchEntradas") fetchEntradas!: (
    params: IEntradaParams
  ) => Promise<IEntradaData | undefined>;
  @Action("fetchEntradasBCR") fetchEntradasBCR!: (
    params: IEntradaParams
  ) => Promise<IEntradaData | undefined>;

  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;

  @Getter("getClientesAll") getClientes!: ICliente[];

  public sortDesc = this.columnsConfig?.codTipoOrden !== "ASC";
  public pagination: IPaged = {
    direction: this.sortDesc ? EDirection.DESC : EDirection.ASC,
    orderBy: this.columnsConfig?.codOrden || "fecMovimiento",
    page: 1,
    size: 10,
  };

  entradaResponse: IEntradaData | null = null;

  getCliente(movimiento: IEntrada | ISalida): ICliente | undefined {
    return this.getClientes.find(
      (c) => c.id === Number(movimiento.puntoservicio.clientexdel.cliente.id)
    );
  }

  get items(): IEntrada[] {
    return this.entradaResponse?.entradas?.content || [];
  }

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [
      {
        key: "actions-left",
        label: "ACCIONES",
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first",
        visible: true,
        class: "table-cell",
        aux: true,
        code: "",
      },
    ];
    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna),
        sortable: true,
        thClass: "table-header",
        tdClass: (_value: unknown, key: string): string => `table-cell-middle ${key}`,
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });
    columnas.push({
      key: "actions-right",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas pb-0",
      tdClass: "table-cell-middle actions",
      visible: true,
      class: "table-cell",
      aux: true,
      code: "",
    });
    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  mounted(): void {
    this.getEntradas();
  }

  async getEntradas(): Promise<void> {
    let params: IMovimientoParams;
    if (this.saldo) {
      params = {
        pkSaldo: this.saldo.id,
      };
    } else {
      params = { ...this.params };
      params.pkDivisa = this.divisa.id;
    }
    params.page = this.pagination.page! - 1;
    params.size = this.pagination.size;
    params.orderBy = this.pagination.orderBy;
    params.direction = this.pagination.direction;

    if (this.isBCR) {
      this.entradaResponse = (await this.fetchEntradasBCR(params)) || null;
    } else {
      this.entradaResponse = (await this.fetchEntradas(params)) || null;
    }
    this.$emit("update:total", this.entradaResponse?.totalEntrada || 0);
    this.$emit("update:totalElements", this.entradaResponse?.entradas.totalElements || 0);
  }

  onChangeCurrentPage(currentPage: number): void {
    this.pagination.page = currentPage;
    this.getEntradas();
  }

  onSortingChange(): void {
    this.$nextTick(async () => {
      this.pagination.direction = this.sortDesc ? EDirection.DESC : EDirection.ASC;
      await this.getEntradas();
      await this.saveColumnsConfig();
    });
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.columnsConfig.codColumna || "",
      codTabla: this.columnsConfig.codTabla,
    };
    if (this.pagination) {
      params.codTipoOrden = this.pagination.direction;
      params.numPagElements = this.pagination.size;
      params.codOrden = this.pagination.orderBy;
    }
    await this.saveColumna(params);
  }
}
