
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  ICliente,
  iniNivelesCLIENTE,
  iniNivelesDELEGACION,
  iniNivelesPAIS,
  INivel,
  IPais,
  IUsuario,
} from "@/entities";
import { iniUsuarioParams, IUsuarioParams } from "@/services";
import { Getter } from "vuex-class";
import { SelectGroupType } from "@/components/look";
import { ROLE_CLIENTE, ROLE_DELEGACION, ROLE_PAIS, ROLE_PS } from "@/constans";
import PFormInputText from "@/components/look/PFormInputText.vue";

@Component({
  components: { PFormInputText },
})
export class UsuarioForm extends Vue {
  @Prop({ required: true }) value!: IUsuarioParams;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));
    this.asignarValorOriginal();
  }
  @Prop({ required: false }) actionName!: string;
  @Getter("getPaises") getPaises!: IPais[];
  @Getter("getLoggedUser") getLoggerUser!: IUsuario;
  valueLocal: IUsuarioParams = iniUsuarioParams;
  optionsPaises: IPais[] = [];
  usuarioLogado: Partial<IUsuario> = {};
  optionsClientes: ICliente[] = [];
  validado = false;
  optionsPs: SelectGroupType[] = [];
  clienteOriginal = 0;
  paisOriginal = 0;

  delegaciones: {
    id: number;
    idClientexdel: number;
    desDelegacion: string;
  }[] = [];

  mounted(): void {
    this.optionsPaises = this.getPaises;
    this.usuarioLogado = this.getLoggerUser;
  }

  get rolePais(): number {
    return ROLE_PAIS;
  }
  get roleCliente(): number {
    return ROLE_CLIENTE;
  }
  get roleDelegacion(): number {
    return ROLE_DELEGACION;
  }
  get rolePS(): number {
    return ROLE_PS;
  }

  get optionsNivel(): INivel[] {
    if (this.usuarioLogado.nivel === ROLE_PAIS) {
      return iniNivelesPAIS.map((nivel) => {
        nivel.descNivel = this.$t(`label.${nivel.codNivel}`);
        return nivel;
      });
    } else if (this.usuarioLogado.nivel === ROLE_CLIENTE) {
      return iniNivelesCLIENTE.map((nivel) => {
        nivel.descNivel = this.$t(`label.${nivel.codNivel}`);
        return nivel;
      });
    } else if (this.usuarioLogado.nivel === ROLE_DELEGACION) {
      return iniNivelesDELEGACION.map((nivel) => {
        nivel.descNivel = this.$t(`label.${nivel.codNivel}`);
        return nivel;
      });
    }
    return [];
  }

  get dameRoles(): string[] {
    const roles: string[] = [];
    this.valueLocal.roles?.forEach((rol) => roles.push(this.$t(`label.${rol.codRol}`)));
    return roles;
  }

  asignarValorOriginal(): void {
    if (this.valueLocal.nivel) {
      this.paisOriginal = this.valueLocal.paises?.length ? this.valueLocal.paises[0] : 0;
      this.clienteOriginal = this.valueLocal.clientes?.length ? this.valueLocal.clientes[0] : 0;
    }
  }

  selectNivel(): void {
    this.valueLocal.paises = [];
    this.valueLocal.clientes = [];
    this.valueLocal.clientesxdel = [];
    this.valueLocal.puntosServicio = [];
  }

  selectPais(): void {
    if (this.valueLocal.paises && this.valueLocal.paises.length) {
      this.$services.clientes._fetchClientesByPais(this.valueLocal.paises[0]).then((response) => {
        if (response) {
          this.optionsClientes = response;
          this.optionsClientes.sort((a: ICliente, b: ICliente) => {
            let fa = a.desCliente.toLowerCase(),
              fb = b.desCliente.toLowerCase();
            if (fa < fb) {
              return -1;
            }
            if (fa > fb) {
              return 1;
            }
            return 0;
          });
          this.optionsClientes.forEach((cliente) => {
            cliente.codDesCliente = cliente.codCliente + " - " + cliente.desCliente;
          });
          if (this.valueLocal.paises![0] != this.paisOriginal) {
            this.valueLocal.clientes = [];
            this.valueLocal.clientesxdel = [];
            this.valueLocal.puntosServicio = [];
            this.asignarValorOriginal();
          }
          this.selectCliente();
        }
      });
    }
  }

  get nivelUsuario(): string {
    const nivelLocal = this.optionsNivel;
    if (nivelLocal.length > 0) {
      return nivelLocal.find((value) => value.id === this.valueLocal.nivel)!.descNivel!;
    } else {
      return "";
    }
  }

  async selectCliente(): Promise<void> {
    if (this.valueLocal.clientes && this.valueLocal.clientes.length) {
      if (this.valueLocal.clientes[0] != this.clienteOriginal) {
        this.valueLocal.clientesxdel = [];
        this.valueLocal.puntosServicio = [];
        this.asignarValorOriginal();
      }
      const cliente = this.optionsClientes.find((c) => c.id === this.valueLocal.clientes![0]);
      if (
        cliente &&
        (this.valueLocal.nivel === ROLE_DELEGACION || this.valueLocal.nivel === ROLE_PS)
      ) {
        const clienteDetail = await this.$services.clientes._fetchCliente(cliente.id!);

        if (this.valueLocal.nivel === ROLE_DELEGACION) {
          const clientexdel = clienteDetail?.clientexdels || [];
          this.delegaciones = clientexdel.map((value) => {
            return {
              id: value.delegacion.id,
              idClientexdel: value.id,
              desDelegacion: value.delegacion.desDelegacion,
            };
          });
        } else if (this.valueLocal.nivel === ROLE_PS) {
          this.optionsPs = [];
          clienteDetail?.clientexdels.forEach((clientexdel) => {
            const delegacion: SelectGroupType = {
              name: clientexdel.delegacion.desDelegacion,
              items: [],
            };
            clientexdel.puntosservicio.forEach((puntoservicio) => {
              delegacion.items.push({
                value: puntoservicio.id,
                text: puntoservicio.desPuntoservicio,
              });
            });
            this.optionsPs.push(delegacion);
          });
        }
      }
    }
  }

  cancel(): void {
    this.$emit("cancel");
  }
  action(action: string): void {
    this.$emit("action", { data: this.valueLocal, action: action });
  }
}
export default UsuarioForm;
