
import { Component, Prop, Vue } from "vue-property-decorator";
import { ICliente, IConfColumnaPais, IField } from "@/entities";
import { Getter } from "vuex-class";
import { EDirection, IColumnaResponse, IDocumentacionNonHistoricalParams } from "@/services";
import DocumentosNonHistoricalTable from "@/components/documentacion/DocumentosNonHistoricalTable.vue";
import VueFilters from "@/vue-filters";
import { Dictionary } from "vue-router/types/router";
import VueMethods from "@/vue-methods";
import DocumentosTable from "@/components/documentacion/DocumentosTable.vue";

@Component({
  components: {
    DocumentosTable,
    DocumentosNonHistoricalTable,
  },
  mixins: [VueFilters, VueMethods],
})
export class ByDocumentsNonHistoricalComponent extends Vue {
  @Prop({ required: true, default: {} }) columnsConfig!: IColumnaResponse;
  @Getter("getClienteSelected") clienteSelected: ICliente | undefined;

  private queryParams = this.$route.query;
  public params: IDocumentacionNonHistoricalParams = {
    page: 1,
    size: 10,
    orderBy: this.columnsConfig.codOrden ?? "id",
    direction:
      this.columnsConfig.codTipoOrden === EDirection.ASC ? EDirection.ASC : EDirection.DESC,
    pkCliente: this.queryParams.pkCliente?.toString(),
    documentTitle: this.queryParams.documentTitle?.toString(),
    documentType: this.queryParams.documentType?.toString(),
    delegaciones: this.$route.query.delegaciones
      ? this.$route.query.delegaciones.toString().split(",")
      : [],
    fechaDocumentoInicio: this.getStartDate(this.queryParams.fechaDocumentoInicio?.toString()),
    fechaDocumentoFin: this.getEndDate(this.queryParams.fechaDocumentoFin?.toString()),
  };

  beforeMount(): void {
    this.params.pkCliente = this.clienteSelected?.id?.toString() ?? this.params.pkCliente;
  }

  mounted(): void {
    this.$router
      .replace({ path: this.$route.path, query: { ...this.params } as Dictionary<any> })
      .catch(() => {
        // Do nothing
      });
  }

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    const columnas: IField[] = [
      {
        key: "actions-left",
        label: this.$t("label.acciones"),
        sortable: false,
        thClass: "table-header table-header-active ",
        tdClass: "table-cell-first",
        visible: true,
        class: "table-cell",
        aux: true,
      },
    ];

    this.columnsConfig?.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna),
        sortable: true,
        thClass: "table-header",
        tdClass: "table-cell-middle",
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        sortKey: column.sortKey,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "DateTime") {
            const date = new Date(value);

            //Si la fecha es 00:00:00.000 la mostramos sin la hora
            if (
              !date.getUTCHours() &&
              !date.getUTCMinutes() &&
              !date.getUTCSeconds() &&
              !date.getUTCMilliseconds()
            ) {
              //Mostramos la fecha sin la hora
              return this.filterDate(value);
            }

            return this.filterDateTime(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });

    columnas.push({
      key: "add-columns",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas",
      tdClass: "table-cell table-add-columns",
      visible: true,
      aux: true,
    });

    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }

  action(action: { action: string; data?: any }): void {
    if (action.action === "print") {
      alert("print");
    } else if (action.action === "download") {
      alert("download");
    }
  }
}
export default ByDocumentsNonHistoricalComponent;
