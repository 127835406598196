import { APPLICATION_JSON } from "@/constans";
import { EventBus } from "@/event-bus";
import Vue from "vue";
import { handleResponse } from "../../handlers";
import { BACKEND_BASE_URL } from "../backend.types";
import { IDivisaResponse, IDivisasService } from "./divisas.types";
import { IResponseError } from "@/entities/common/common.types";

export class DivisasService implements IDivisasService {
  async _fetchAllDivisas(): Promise<IDivisaResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDivisaResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/divisa`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-saldosonline-divisas-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
}
