import {
  BACKEND_BASE_URL,
  IDocumentacionFajosParams,
  IDocumentacionImportacionCreateParams,
  IDocumentacionImportacionCreateResponse,
  IDocumentacionImportacionResponse,
  IDocumentacionNonHistoricalResponse,
  serialize,
} from "@/services";
import { APPLICATION_JSON } from "@/constans";
import { handleResponse } from "@/services/handlers";
import { IParametro, IResponseError } from "@/entities";
import Vue from "vue";
import { EventBus } from "@/event-bus";
import {
  IDocumentacionParams,
  IDocumentacionResponse,
  IDocumentacionService,
} from "@/services/saldosonline/documentacion/documentacion.types";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  IDocumento,
  IDocumentoByImportacionTableItem,
  IDocumentoNonHistoricalTableItem,
} from "@/entities/centros-efectivo-backend/documento.types";

export class DocumentacionService implements IDocumentacionService {
  async _fetch(params: IDocumentacionParams): Promise<IDocumentacionResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDocumentacionResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/documentacion`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchDocumentos(
    params: IDocumentacionParams
  ): Promise<IDocumentacionNonHistoricalResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<
      IDocumentacionNonHistoricalResponse,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/documento`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async _fetchImportacion(
    params: IDocumentacionParams
  ): Promise<IDocumentacionImportacionResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<
      IDocumentacionImportacionResponse,
      IResponseError
    >(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/documentacion/importados`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchDocFajos(
    params: IDocumentacionFajosParams
  ): Promise<IDocumentacionResponse | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IDocumentacionResponse, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/documentacion/getDocFajos`, {
        headers,
        params,
        paramsSerializer: (p) => serialize(p),
      })
    );
    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchTiposDocumentos(): Promise<IParametro[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const { response, error } = await handleResponse<IParametro[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/documentacion/tipo`, { headers })
    );
    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async fetchTiposDocumentosByPais(pkPais: number): Promise<IParametro[] | undefined> {
    const headers = {
      Accept: APPLICATION_JSON,
    };
    const params = { pkPais: pkPais };
    const { response, error } = await handleResponse<IParametro[], IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/documentacion/tipoByPais`, {
        headers,
        params,
      })
    );
    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }

  async downloadFile(doc: IDocumento): Promise<AxiosResponse | undefined> {
    const params = { id: doc.documentId, tituloDocumento: doc.documentTitle };
    const postConfig = {
      params,
      headers: {
        Accept: "/",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/documentacion/fichero`, {}, postConfig)
    );

    if (error) {
      return undefined;
    } else {
      return response;
    }
  }

  async downloadAllFiles(params: IDocumentacionParams): Promise<AxiosResponse | undefined> {
    const postConfig = {
      headers: {
        Accept: "/",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/documentacion/ficheros/todos`, params, postConfig)
    );

    if (error) {
      return undefined;
    } else {
      return response;
    }
  }

  async downloadFileNonHistorical(
    doc: IDocumentoNonHistoricalTableItem
  ): Promise<AxiosResponse | undefined> {
    const params = null;
    const axiosRequestConfig = {
      params,
      headers: {
        Accept: "/",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.get(`${BACKEND_BASE_URL}/api/documento/` + doc.id, axiosRequestConfig)
    );

    if (error) {
      return undefined;
    } else {
      return response;
    }
  }

  async downloadFileOfImportation(
    doc: IDocumentoByImportacionTableItem
  ): Promise<AxiosResponse | undefined> {
    const params = null;
    const axiosRequestConfig = {
      params,
      headers: {
        Accept: "/",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.get(
        `${BACKEND_BASE_URL}/api/documentacion/importacion/` + doc.id,
        axiosRequestConfig
      )
    );

    if (error) {
      return undefined;
    } else {
      return response;
    }
  }

  async downloadByDelegation(
    codDelegacion: string,
    tipoDocumento: string,
    documentos: unknown
  ): Promise<AxiosResponse | undefined> {
    const postConfig = {
      params: {
        codDelegacion: codDelegacion,
        tipoDocumento: tipoDocumento,
      },
      headers: {
        Accept: "/",
        "X-Requested-With": "XMLHttpRequest",
      },
      responseType: "blob",
    } as AxiosRequestConfig;
    const { response, error } = await handleResponse<Blob, IResponseError>(
      Vue.$axios.post(`${BACKEND_BASE_URL}/api/documentacion/ficheros`, documentos, postConfig)
    );

    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response;
    }
  }

  async remove(doc: IDocumento): Promise<void> {
    const headers = {
      Accept: "*/*",
    };
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.delete(`${BACKEND_BASE_URL}/api/documentacion/` + doc.documentId, { headers })
    );
    if (error || response!.status != 200) {
      EventBus.$emit("on-service-usuario-error", error?.response?.data);
      return undefined;
    }
  }

  async removeNonHistorical(doc: IDocumentoNonHistoricalTableItem): Promise<void> {
    const headers = {
      Accept: "*/*",
    };
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.delete(`${BACKEND_BASE_URL}/api/documento/` + doc.id, { headers })
    );
    if (error || response!.status != 200) {
      EventBus.$emit("on-service-usuario-error", error?.response?.data);
      return undefined;
    }
  }
  async removeImported(doc: IDocumentoByImportacionTableItem): Promise<void> {
    const headers = {
      Accept: "*/*",
    };
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.delete(`${BACKEND_BASE_URL}/api/documentacion/importacion/` + doc.id, { headers })
    );
    if (error || response!.status != 200) {
      EventBus.$emit("on-service-usuario-error", error?.response?.data);
      return undefined;
    }
  }

  async approveImported(doc: IDocumentoByImportacionTableItem): Promise<void> {
    const headers = {
      Accept: "*/*",
    };
    const { response, error } = await handleResponse<any, IResponseError>(
      Vue.$axios.patch(`${BACKEND_BASE_URL}/api/documentacion/importacion/` + doc.id, { headers })
    );
    if (error || response!.status != 200) {
      EventBus.$emit("on-service-usuario-error", error?.response?.data);
      return undefined;
    }
  }

  async saveDocumentacionImportacion(
    params: IDocumentacionImportacionCreateParams
  ): Promise<IDocumentacionImportacionCreateResponse | undefined> {
    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const formData = new FormData();
    const files: File[] = Array.from(params.files);
    files.forEach((file) => {
      formData.append("files", file);
    });

    const { response, error } = await handleResponse<
      IDocumentacionImportacionCreateResponse,
      IResponseError
    >(Vue.$axios.post(`${BACKEND_BASE_URL}/api/documentacion/importados`, formData, config));
    if (error) {
      EventBus.$emit("on-service-documentacion-error", error.response?.data);
      return undefined;
    } else {
      return response!.data;
    }
  }
}
