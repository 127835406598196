
import { Component, Prop, Vue } from "vue-property-decorator";
import PIcon from "../PIcon/PIcon.vue";

@Component({
  components: {
    PIcon,
  },
})
export class PButton extends Vue {
  @Prop({ required: false, default: "primary" }) variant!:
    | "primary"
    | "secondary"
    | "circle"
    | "gray-circle"
    | "transparent";
  @Prop({ required: false, default: false }) disabled!: boolean;
  @Prop({ required: false, default: "" }) icon!: string;
  @Prop({ required: false, default: "" }) iType!: string;
  @Prop({ required: false, default: 16 }) iSize!: number;
  @Prop({ required: false, default: "#6f6f6f" }) iColor!: string;
  @Prop({ required: false, default: false }) isActive!: boolean;
  @Prop({ required: false, default: "button" }) type!: "button" | "submit" | "reset";
  get variantCop(): string {
    let returnTemp: string = this.variant + "_PButton";
    if (this.isActive) {
      returnTemp = returnTemp + " active_PButton";
    }
    return returnTemp;
  }
  get giveMeClassDisabled(): string {
    if (this.disabled) {
      return "disabled_PButton";
    } else {
      return "";
    }
  }
  get classMarginIcon(): string {
    if (this.icon) {
      return "marginIconTex_PButton";
    } else {
      return "";
    }
  }
  click(): void {
    if (!this.disabled) {
      this.$emit("click");
    }
  }
}
export default PButton;
