
import { Component, Prop, Vue } from "vue-property-decorator";
import { IOfertaDemanda } from "@/entities";
import VueFilters from "@/vue-filters";
import DelegacionesOpcionales from "@/pages/transferencias/common/DelegacionesOpcionales.vue";

@Component({
  name: "detalle-demanda",
  components: { DelegacionesOpcionales },
  mixins: [VueFilters],
})
export default class OfertaDemandaDetalle extends Vue {
  @Prop({ required: true }) ofertaDemanda!: IOfertaDemanda;
  public fecha = "";
  isShowBankField: boolean | undefined = false;

  mounted(): void {
    this.fecha = this.filterDate(this.ofertaDemanda.fecExpirar!);
    this.isShowBankField = this.ofertaDemanda.clientexdel.cliente.pais.hasInfoBancaria;
  }

  get title(): string {
    return this.ofertaDemanda.cantidadDemandada
      ? this.$t("label.demandas")
      : this.$t("label.ofertas");
  }
  get titleDetalle(): string {
    return this.ofertaDemanda.cantidadDemandada
      ? this.$t("label.detalles.demanda")
      : this.$t("label.detalles.oferta");
  }
}
