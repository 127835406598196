
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Getter } from "vuex-class";
import VueMethods from "@/vue-methods";
import { IReporteFilters, IUsuario } from "@/entities";
import { ROLE_PAIS } from "@/constans";
import { Locale } from "@/store";

@Component({
  mixins: [VueMethods],
})
export class ReportesFilterForm extends Vue {
  @Prop({ required: true }) value!: IReporteFilters;
  @Prop({ required: true }) estados!: string[];
  @Prop({ required: true }) tipos!: string[];
  @Prop({ required: false }) actionName!: string;
  @Prop({ required: false }) textActionName!: string;

  @Getter("getLoggedUser") loggedUser: IUsuario | undefined;
  @Getter("getLocale") locale!: Locale;
  @Watch("value", { immediate: true, deep: true })
  onChangeValue(): void {
    this.valueLocal = JSON.parse(JSON.stringify(this.value));

    this.$set(
      this.valueLocal,
      "fecSolicitud",
      this.valueLocal.fecSolicitud ? new Date(this.valueLocal.fecSolicitud) : undefined
    );
    this.$set(
      this.valueLocal,
      "fecEjecucion",
      this.valueLocal.fecEjecucion ? new Date(this.valueLocal.fecEjecucion) : undefined
    );
  }

  validado = false;
  valueLocal: IReporteFilters = {
    codUsuario: undefined,
    filename: undefined,
    tipo: [],
    estado: [],
    fecSolicitud: undefined,
    fecEjecucion: undefined,
  };
  initialValueLocal: IReporteFilters = {
    codUsuario: undefined,
    filename: undefined,
    tipo: [],
    estado: [],
    fecSolicitud: undefined,
    fecEjecucion: undefined,
  };

  limpiar(): void {
    this.valueLocal.codUsuario = undefined;
    this.valueLocal.filename = undefined;
    this.valueLocal.estado = [];
    this.valueLocal.tipo = [];
    this.valueLocal.fecSolicitud = undefined;
    this.valueLocal.fecEjecucion = undefined;

    if (!this.isInitialObject(this.value, this.initialValueLocal)) {
      this.$emit("input", this.valueLocal);
      this.$emit("cancel", { action: "clean" });
    }
  }
  action(action: string): void {
    this.$emit("input", this.valueLocal);
    this.$emit("action", { data: this.valueLocal, action: action });
  }

  get optionEstados(): { key: string; value: string }[] {
    return this.estados.map((estado) => {
      return {
        key: estado,
        value: this.$t(`label.reporte.estado.${estado.toLowerCase()}`),
      };
    });
  }

  get optionTipos(): { key: string; value: string }[] {
    return this.tipos.map((tipo) => {
      return { key: tipo, value: this.$t(`label.reporte.tipo.${tipo.toLowerCase()}`) };
    });
  }

  get rolePais(): number {
    return ROLE_PAIS;
  }
}
export default ReportesFilterForm;
